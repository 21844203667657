import { EditorEntityModule, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import {
  EntityConfigModule,
  EntityConfigParam,
  EntityConfigParamRef,
  EntityConfigParamType,
  EntityConfigResource,
} from '../types/EntityConfig';

type ParamRefSearchResult = {
  referencer: EntityConfigResource | EntityConfigModule;
  path: string[];
};

const isParamRef = (value: unknown): boolean => {
  if (!value) { return false; }
  if (typeof value !== 'object') { return false; }
  if (!('type' in value) && !('key' in value)) { return false; }
  const potentialRefValue = value as EntityConfigParamRef;
  if (typeof potentialRefValue.key !== 'string') { return false; }
  if (typeof potentialRefValue.type !== 'string') { return false; }
  if (!Object.values(EntityConfigParamType)
    .includes(potentialRefValue.type as EntityConfigParamType)) { return false; }
  return true;
};

export function getParamReferences(
  param: EntityConfigParam,
  resources: EditorEntityResource[],
  modules: EditorEntityModule[],
) {
  const findRefs = (
    resourceContext: EntityConfigResource | EntityConfigModule,
    path: string[],
    value: unknown,
  ): (undefined | ParamRefSearchResult)[] => {
    if (!value) { return [undefined]; }
    if (typeof value === 'string') { return [undefined]; }
    if (typeof value === 'number') { return [undefined]; }
    if (typeof value !== 'object') { return [undefined]; }
    if (Array.isArray(value)) {
      return value.flatMap((item, i) => findRefs(resourceContext, [...path, i.toString()], item));
    }
    if (isParamRef(value)) {
      if ((value as EntityConfigParamRef).key !== param.key) {
        return [undefined];
      }
      if ((value as EntityConfigParamRef).type !== param.type) {
        return [undefined];
      }
      return [{ referencer: resourceContext, path }];
    }
    return Object
      .entries(value)
      .flatMap(([innerKey, innerValue]) => findRefs(
        resourceContext,
        [...path, innerKey],
        innerValue,
      ));
  };

  const result = [
    ...resources
      .flatMap((resourceToSearch) => Object.entries(resourceToSearch.state)
        .flatMap(([key, value]) => findRefs(
          resourceToSearch.state,
          [key],
          value,
        ))),
    ...modules
      .flatMap((moduleToSearch) => Object.entries(moduleToSearch.state)
        .flatMap(([key, value]) => findRefs(
          moduleToSearch.state,
          [key],
          value,
        ))),
  ].filter((v) => !!v);
  return result;
}

export function getJsonValueForField(value: string) {
  try {
    return JSON.parse(JSON.stringify(value));
  } catch {
    return value;
  }
}

export function getJsonValueForDisplay(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}
