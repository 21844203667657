import { NCButton, NCInputText } from '@daupler/nexus-components';
import './ConfigVisualEditor.css';

type ConfigVisualEditorCodeProps = {
  yamlValue: string;
  onYamlCancel: () => void;
  onYamlChange: (config: string) => void;
  onYamlSave: () => void;
};

export function ConfigVisualEditorCode({
  yamlValue,
  onYamlCancel,
  onYamlChange,
  onYamlSave,
}: ConfigVisualEditorCodeProps) {
  return (
    <div className="config_visual_editor__text_editor">
      <NCInputText
        multiline
        rows={3}
        name="configuration-raw"
        hint=""
        value={yamlValue}
        className="config_visual_editor__code"
        onChange={(event) => {
          onYamlChange(event.target.value);
        }}
        label="Configuration Raw"
        labelHidden
      />
      <div className="nc-flex nc-flex--justify_end nc-flex--gap_1 nc-l-pa_200_mobile">
        <NCButton
          color={NCButton.colors.GREY}
          appearance={NCButton.appearances.OUTLINE}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={onYamlCancel}
        >
          Cancel
        </NCButton>
        <NCButton
          color={NCButton.colors.PRIMARY}
          appearance={NCButton.appearances.OUTLINE}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={onYamlSave}
        >
          Save
        </NCButton>
      </div>
    </div>
  );
}
