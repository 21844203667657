import {
  FormField,
  NCWell,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import {
  CallScheduledUsersTeamFormationOption,
  CallSelectedRolesTeamFormationOption,
  CallSelectedUsersTeamFormationOption,
  EntityConfigIncidentTransferMode,
  EntityConfigResourceIncidentVersion,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function IncidentVersionForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: dataValue,
  } = resourceData as EntityConfigResourceIncidentVersion ?? { data: {} };
  const {
    team_formation_options: teamFormationOptionsValue,
    transfer_mode: transferModeValue,
  } = dataValue ?? {};
  const {
    call_scheduled_users: callScheduledUsersValue,
    call_selected_roles: callSelectedRolesValue,
    call_selected_users: callSelectedUsersValue,
  } = teamFormationOptionsValue ?? {};

  const transferMode: FormField<EntityConfigIncidentTransferMode | ''> = {
    name: 'transferMode',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: transferModeValue ?? 'shared',
  };
  const callSelectedUsersOptions: FormField<CallSelectedUsersTeamFormationOption> = {
    name: 'callSelectedUsersOptions',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: callSelectedUsersValue ?? { _roles_ref_list: undefined, _target_role_ref: undefined },
  };
  const callSelectedRolesOptions: FormField<CallSelectedRolesTeamFormationOption> = {
    name: 'callSelectedRolesOptions',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: callSelectedRolesValue ?? { _roles_ref_list: undefined },
  };
  const callScheduledUsersOptions: FormField<CallScheduledUsersTeamFormationOption> = {
    name: 'callScheduledUsersOptions',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: callScheduledUsersValue ?? { _target_role_ref: undefined },
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    transferMode,
    callSelectedUsersOptions,
    callSelectedRolesOptions,
    callScheduledUsersOptions,
  });

  const resolveCallSelectedUsersTeamFormationOptionValue = (
    option: CallSelectedUsersTeamFormationOption,
  ): CallSelectedUsersTeamFormationOption | null => {
    const {
      _roles_ref_list: rolesRefList,
      _target_role_ref: targetRoleRef,
    } = option;
    if (!rolesRefList?.length || !targetRoleRef) { return null; }
    return option;
  };
  const resolveCallSelectedRolesTeamFormationOptionValue = (
    option: CallSelectedUsersTeamFormationOption,
  ): CallSelectedUsersTeamFormationOption | null => {
    const {
      _roles_ref_list: rolesRefList,
    } = option;
    if (!rolesRefList?.length) { return null; }
    return option;
  };
  const resolveCallScheduledUsersTeamFormationOptionValue = (
    option: CallSelectedUsersTeamFormationOption,
  ): CallSelectedUsersTeamFormationOption | null => {
    const {
      _target_role_ref: targetRoleRef,
    } = option;
    if (!targetRoleRef) { return null; }
    return option;
  };
  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        transfer_mode: formState.transferMode.value,
        team_formation_options: {
          call_selected_users: resolveCallSelectedUsersTeamFormationOptionValue(
            formState.callSelectedUsersOptions.value,
          ),
          call_selected_roles: resolveCallSelectedRolesTeamFormationOptionValue(
            formState.callSelectedRolesOptions.value,
          ),
          call_scheduled_users: resolveCallScheduledUsersTeamFormationOptionValue(
            formState.callScheduledUsersOptions.value,
          ),
        },
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />

      <div>
        <p className="nc-l-mb_utilities_25_mobile nc-l-mt_200_mobile nc-t-body_medium_mobile nc-t-grey_900">
          Call Selected Users
        </p>
        <NCWell>
          <p className="nc-t-body_medium_mobile nc-t-grey_700">Please note: BOTH fields are required</p>

          <ConfigVisualEditorResourceRefControl
            label="Roles"
            className="nc-l-mt_200_mobile"
            name={`roles-${formState.callSelectedUsersOptions.name}`}
            multiple
            resources={resources}
            resourceType={EntityConfigResourceType.WORKGROUP_ROLES}
            entityId={entityId}
            onChange={(event) => onFormChange(
              formState.callSelectedUsersOptions.name,
              {
                // eslint-disable-next-line no-underscore-dangle
                _target_role_ref: formState.callSelectedUsersOptions.value._target_role_ref,
                _roles_ref_list: Array.from(event.target.options)
                  .filter((option) => option.selected)
                  .map((option) => ({
                    key: option.value,
                    type: EntityConfigResourceType.WORKGROUP_ROLES,
                  })),
              },
            )}
            // eslint-disable-next-line max-len, no-underscore-dangle
            value={formState.callSelectedUsersOptions.value._roles_ref_list?.map((ref) => ref.key) ?? []}
          />
          <ConfigVisualEditorResourceRefControl
            label="Target Role"
            className="nc-l-mt_200_mobile"
            name={`target-${formState.callSelectedUsersOptions.name}`}
            resources={resources}
            resourceType={EntityConfigResourceType.INCIDENT_ROLES}
            entityId={entityId}
            onChange={(event) => onFormChange(
              formState.callSelectedUsersOptions.name,
              {
                // eslint-disable-next-line no-underscore-dangle
                _roles_ref_list: formState.callSelectedUsersOptions.value._roles_ref_list,
                _target_role_ref: {
                  key: event.target.value,
                  type: EntityConfigResourceType.INCIDENT_ROLES,
                },
              },
            )}
            // eslint-disable-next-line no-underscore-dangle
            value={formState.callSelectedUsersOptions.value._target_role_ref?.key ?? ''}
          />
        </NCWell>
      </div>

      <div>
        <p className="nc-l-mb_utilities_25_mobile nc-l-mt_200_mobile nc-t-body_medium_mobile nc-t-grey_900">
          Call Selected Roles
        </p>
        <NCWell>
          <p className="nc-t-body_medium_mobile nc-t-grey_700">
            For each selected workgroup_role there MUST
            be an incident_role with the same display name.
          </p>
          <ConfigVisualEditorResourceRefControl
            label="Roles"
            className="nc-l-mt_200_mobile"
            name={`roles-${formState.callSelectedRolesOptions.name}`}
            multiple
            resources={resources}
            resourceType={EntityConfigResourceType.WORKGROUP_ROLES}
            entityId={entityId}
            onChange={(event) => onFormChange(
              formState.callSelectedRolesOptions.name,
              {
                _roles_ref_list: Array.from(event.target.options)
                  .filter((option) => option.selected)
                  .map((option) => ({
                    key: option.value,
                    type: EntityConfigResourceType.WORKGROUP_ROLES,
                  })),
              },
            )}
            // eslint-disable-next-line max-len, no-underscore-dangle
            value={formState.callSelectedRolesOptions.value._roles_ref_list?.map((ref) => ref.key) ?? []}
          />
        </NCWell>
      </div>

      <div>
        <p className="nc-l-mb_utilities_25_mobile nc-l-mt_200_mobile nc-t-body_medium_mobile nc-t-grey_900">
          Call Scheduled Users
        </p>
        <NCWell>
          <ConfigVisualEditorResourceRefControl
            label="Target Role"
            className="nc-l-mt_200_mobile"
            name={`target-${formState.callScheduledUsersOptions.name}`}
            resources={resources}
            resourceType={EntityConfigResourceType.INCIDENT_ROLES}
            entityId={entityId}
            onChange={(event) => onFormChange(
              formState.callScheduledUsersOptions.name,
              {
                _target_role_ref: {
                  key: event.target.value,
                  type: EntityConfigResourceType.INCIDENT_ROLES,
                },
              },
            )}
            // eslint-disable-next-line no-underscore-dangle
            value={formState.callScheduledUsersOptions.value._target_role_ref?.key ?? ''}
          />
        </NCWell>
      </div>
    </>
  );
}
