import {
  FormField,
  NCInputCheckbox,
  NCInputText,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { ModuleFormProps } from './module-form-types';
import {
  EntityConfigModuleDaytimeDispatch,
  EntityConfigParamRef,
  EntityConfigParamType,
  EntityConfigRef,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { useSyncModuleForm } from '../../hooks/useSyncModuleForm';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function DaytimeDispatchForm({
  entityId,
  module,
  moduleType,
  onChange,
  onValidate,
  params,
  resources,
}: ModuleFormProps) {
  const {
    data: {
      _extent_ref: extentRefValue,
      _dispatch_versions_ref_list: dispatchVersionsRefListValue,
      _undispatched_versions_ref_list: undispatchedVersionsRefListValue,
      enabled: enabledValue,
    },
  } = module as EntityConfigModuleDaytimeDispatch ?? { data: {} };
  const extentRef: FormField<EntityConfigParamRef | undefined> = {
    invalidMessage: '',
    name: 'extentRef',
    validate: () => true,
    validMessage: '',
    value: extentRefValue ?? undefined,
  };
  const dispatchVersionsRefList: FormField<EntityConfigRef[]> = {
    invalidMessage: '',
    name: 'dispatchVersionsRefList',
    validate: () => true,
    validMessage: '',
    value: dispatchVersionsRefListValue ?? [],
  };
  const undispatchedVersionsRefList: FormField<EntityConfigRef[]> = {
    invalidMessage: '',
    name: 'undispatchedVersionsRefList',
    validate: () => true,
    validMessage: '',
    value: undispatchedVersionsRefListValue ?? [],
  };
  const enabled: FormField<boolean> = {
    invalidMessage: '',
    name: 'enabled',
    validate: () => true,
    validMessage: '',
    value: enabledValue ?? false,
  };

  const {
    formState,
    isFormValid,
    onChange: onFormChange,
  } = useForm({
    extentRef,
    dispatchVersionsRefList,
    undispatchedVersionsRefList,
    enabled,
  });
  useSyncModuleForm({
    isFormValid,
    module: {
      data: {
        _dispatch_versions_ref_list: formState.dispatchVersionsRefList.value,
        _undispatched_versions_ref_list: formState.undispatchedVersionsRefList.value,
        _extent_ref: formState.extentRef.value,
        enabled: formState.enabled.value,
      },
    },
    onChange,
    onValidate,
  });

  return (
    <>
      <NCInputText
        name="module-type"
        label="Module Type"
        value={moduleType}
        disabled
      />
      <NCInputCheckbox
        label="Enabled?"
        className="nc-l-mt_200_mobile"
        name={formState.enabled.name}
        checked={formState.enabled.value}
        onChange={() => onFormChange(formState.enabled.name, !formState.enabled.value)}
      />
      <NCSelect
        className="nc-l-mt_200_mobile"
        label="Extent"
        name={formState.extentRef.name}
        options={params.filter(({ state: param }) => param.type === EntityConfigParamType.EXTENT)
          .map(({ state: param }) => ({
            label: param.description,
            value: param.key,
          }))}
        onChange={(event) => {
          onFormChange(
            formState.extentRef.name,
            {
              key: event.target.value,
              type: EntityConfigParamType.EXTENT,
            },
          );
        }}
        value={formState.extentRef.value?.key ?? ''}
      />
      <ConfigVisualEditorResourceRefControl
        className="nc-l-mt_200_mobile"
        label="Dispatch Versions"
        name={formState.dispatchVersionsRefList.name}
        multiple
        resources={resources}
        resourceType={EntityConfigResourceType.INCIDENT_VERSIONS}
        entityId={entityId}
        onChange={(event) => {
          onFormChange(
            formState.dispatchVersionsRefList.name,
            Array.from(event.target.options)
              .filter((option) => option.selected)
              .map((option) => ({
                key: option.value,
                type: EntityConfigResourceType.INCIDENT_VERSIONS,
              })),
          );
        }}
        value={formState.dispatchVersionsRefList.value.map(({ key }) => key)}
      />
      <ConfigVisualEditorResourceRefControl
        className="nc-l-mt_200_mobile"
        label="Umdispatched Versions"
        name={formState.undispatchedVersionsRefList.name}
        multiple
        resources={resources}
        resourceType={EntityConfigResourceType.INCIDENT_VERSIONS}
        entityId={entityId}
        onChange={(event) => {
          onFormChange(
            formState.undispatchedVersionsRefList.name,
            Array.from(event.target.options)
              .filter((option) => option.selected)
              .map((option) => ({
                key: option.value,
                type: EntityConfigResourceType.INCIDENT_VERSIONS,
              })),
          );
        }}
        value={formState.undispatchedVersionsRefList.value.map(({ key }) => key)}
      />
    </>
  );
}
