import { NCButton, NCInputText } from '@daupler/nexus-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { getKeyValueFromRRule } from '../../utils/rrule-util';
import { getDaysOfTheWeek } from '../../utils/datetime';
import { InputMultiSelectControl } from '../InputMultiSelectControl';

function InputRRuleWeekly({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [interval, setIntervalValue] = useState(getKeyValueFromRRule(value).INTERVAL ?? '1');
  const updateInterval = (event: ChangeEvent<HTMLInputElement>) => {
    setIntervalValue(event.target.value);
  };

  const [day, setDay] = useState(() => {
    const rruleKeyVal = getKeyValueFromRRule(value);
    if (rruleKeyVal.BYDAY) {
      return rruleKeyVal.BYDAY.split(',');
    }
    return [];
  });
  const updateDay = (event: ChangeEvent<HTMLSelectElement>) => {
    setDay(Array.from(event.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value as string));
  };
  const clearDays = () => {
    setDay([]);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.INTERVAL !== interval || (parsedValue.BYDAY ?? '') !== day.join(',')) {
      onChange({
        INTERVAL: interval,
        ...(day.length ? { BYDAY: day.join(',') } : {}),
      });
    }
  }, [interval, day, onChange, value]);

  return (
    <>
      <NCInputText
        name="rrule-weekly-recurrence-count"
        label="Every N Weeks"
        min={1}
        onChange={updateInterval}
        step={1}
        type="number"
        value={interval}
      />
      <InputMultiSelectControl
        label="On Day(s)"
        className="nc-l-mt_200_mobile"
        name="rrule-weekly-recurrence-day"
        onChange={updateDay}
        options={getDaysOfTheWeek().map((weekday) => ({
          label: weekday.label,
          value: weekday.abbr,
        }))}
        value={day}
      />
      <NCButton
        onClick={clearDays}
        appearance={NCButton.appearances.LINK}
        width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
        className="nc-l-mt_utilities_50_mobile"
      >
        Clear Days
      </NCButton>
    </>
  );
}

export default InputRRuleWeekly;
