import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  debounce,
  NCButton,
  NCInputText,
} from '@daupler/nexus-components';
import { EntityDetail } from '../types/Entity';
import './ConfigVisualEditorHeader.css';
import { NavToolPicker } from './NavToolPicker';
import { EditorMode } from '../types/ConfigEditor';

type ConfigVisualEditorHeaderProps = {
  entity: EntityDetail | null;
  editorMode: EditorMode;
  isDrawerOpen: boolean;
  isObjectsMenuOpen: boolean;
  onDrawerClick: () => void;
  onEditorModeChange: (editorMode: EditorMode) => void;
  onObjectsMenuClick: () => void;
  onSave: () => void;
  onSearchFilterUpdate: (value: string) => void;
  searchFilter: string;
  isSaving?: boolean;
};

export function ConfigVisualEditorHeader({
  editorMode,
  entity,
  isDrawerOpen,
  isObjectsMenuOpen,
  isSaving,
  searchFilter,
  onDrawerClick,
  onEditorModeChange,
  onObjectsMenuClick,
  onSearchFilterUpdate,
  onSave,
}: ConfigVisualEditorHeaderProps) {
  const [isSearching, setIsSearching] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onSearchChangeDebounced = useRef(
    debounce(onSearchFilterUpdate, 250),
  );

  const searchFilterRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!searchFilterRef.current) { return; }
    if (searchFilter === searchFilterRef.current.value) {
      return;
    }
    searchFilterRef.current.value = searchFilter;
  }, [searchFilter]);

  return (
    <div className="config_visual_editor_header">
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-flex--gap_1 config_visual_editor__title_row">
        <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
          <NavToolPicker />
          <div className={clsx('config_visual_editor_header__title', { 'config_visual_editor_header__title--hidden': isSearching })}>
            <span className="nc-t-sub_text_light_mobile nc-t-h3_light_tablet">{entity?.name}</span>
            {' '}
            <span className="nc-t-info_text_medium_mobile nc-t-h5_medium_tablet">{`(${entity?.shortName})`}</span>
          </div>
        </div>

        <div
          className={clsx('nc-flex nc-flex--align_end nc-flex--gap_1', {
            'config_visual_editor_header__search_grp--wide': isSearching,
          })}
        >
          <div className="config_visual_editor_header__search_btn">
            <NCButton
              color={NCButton.colors.GREY}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              onClick={() => setIsSearching(!isSearching)}
            >
              {isSearching ? (
                <i className="fa-solid fa-times fa-fw" />
              ) : (
                <i className="fa-solid fa-search fa-fw" />
              )}
            </NCButton>
          </div>
          <div
            className={clsx('config_visual_editor_header__search_field', {
              'config_visual_editor_header__search_field--searching': isSearching,
              'nc-flex_item--1': isSearching,
            })}
          >
            <NCInputText
              name="search"
              label="Search"
              labelHidden
              onChange={(event) => onSearchChangeDebounced.current(event.target.value)}
              tag={(<i className="fa-solid fa-search" />)}
              refToInput={searchFilterRef}
              button={searchFilter ? {
                label: (<i className="fa-solid fa-fw fa-circle-x nc-t-grey_500" />),
                onClick: () => {
                  onSearchFilterUpdate('');
                  if (searchFilterRef.current) {
                    searchFilterRef.current.value = '';
                  }
                },
              } : undefined}
            />
          </div>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            className={clsx({ 'config_visual_editor_header__view_toggle--hidden': isSearching })}
            color={NCButton.colors.GREY}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => {
              onEditorModeChange(editorMode === EditorMode.VISUAL
                ? EditorMode.YAML : EditorMode.VISUAL);
              navigate(`/config/entities/${entity?.id ?? ''}?${searchParams.toString()}`);
            }}
          >
            {editorMode === EditorMode.VISUAL ? (<i className="fa-solid fa-fw fa-code" />) : (<i className="fa-solid fa-fw fa-window" />)}
          </NCButton>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            className={clsx({ 'config_visual_editor_header__view_toggle--hidden': isSearching })}
            color={NCButton.colors.GREY}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onSave}
            disabled={isSaving}
          >
            {isSaving ? (
              <i className="fa-solid fa-spinner nc-anim-rotate" />
            ) : (
              <i className="fa-solid fa-save" />
            )}
          </NCButton>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            className={clsx({ 'config_visual_editor_header__view_toggle--hidden': isSearching })}
            color={NCButton.colors.GREY}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => {
              navigate(`load-data?${searchParams.toString()}`);
            }}
          >
            <i className="fa-solid fa-folder" />
          </NCButton>
        </div>
      </div>

      <div className="nc-flex nc-flex--align_end nc-flex--justify_between config_visual_editor__tool_row">
        <div className="nc-flex nc-flex--align_end nc-flex--gap_1">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            color={NCButton.colors.DARK}
            onClick={onDrawerClick}
            active={isDrawerOpen}
          >
            Objects
            {' '}
            {searchParams.get('type-filter')?.length ? <i className="fa-light fa-filter" /> : null}
          </NCButton>
        </div>

        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          color={NCButton.colors.DARK}
          onClick={onObjectsMenuClick}
          active={isObjectsMenuOpen}
        >
          Params
        </NCButton>
      </div>
    </div>
  );
}
