import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ConfigVisualEditorResourceModal } from '../components/ConfigVisualEditorResourceModal';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResource, EntityConfigResourceType } from '../types/EntityConfig';

export function EntitiesAddResourceRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    params,
    resources,
    saveEntityConfig,
    setIsSaving,
    entity,
    utilityCategories,
  } = useEntityConfigEditor();

  const handleSubmit = async (resource: EntityConfigResource) => {
    resources.add(resource);
    setIsSaving(true);
    saveEntityConfig({ background: true });
    setIsSaving(false);
  };

  const handleExit = () => {
    if (!entity) { return; }
    searchParams.delete('resource-type');
    if (location.state?.isRefLink || location.state?.wasAppLink) {
      navigate(-1);
    } else {
      navigate(`/config/entities/${entity.id}?${searchParams.toString()}`);
    }
  };

  const typeToAdd = searchParams.get('resource-type');

  if (!entity) { return null; }

  return (
    <ConfigVisualEditorResourceModal
      entityId={entity?.id ?? ''}
      onBack={handleExit}
      resourceType={typeToAdd as EntityConfigResourceType}
      onClose={handleExit}
      onSubmit={handleSubmit}
      params={params.data}
      resources={resources.data}
      resource={location.state?.resourceData}
      wipResource={null}
      utilityCategories={utilityCategories}
    />
  );
}
