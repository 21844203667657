import { NCSelect } from '@daupler/nexus-components';
import { ComponentProps } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  EntityConfigResourceType,
} from '../types/EntityConfig';
import { EditorEntityResource } from '../hooks/useEntityConfigEditor';
import './ConfigVisualEditorResourceRefControl.css';
import { InputMultiSelectControl } from './InputMultiSelectControl';

type ConfigVisualEditorResourceRefControlProps = Omit<ComponentProps<typeof NCSelect>, 'options'> & {
  entityId: string;
  resources: EditorEntityResource[];
  resourceType: EntityConfigResourceType;
};

export function ConfigVisualEditorResourceRefControl(
  props: ConfigVisualEditorResourceRefControlProps,
) {
  const {
    className,
    entityId,
    multiple,
    resources,
    resourceType,
    value,
  } = props;

  const [searchParams] = useSearchParams();

  const eligibleResources = resources
    .filter(({ state: { type } }) => resourceType === type);

  const ControlComponent = multiple ? InputMultiSelectControl : NCSelect;

  return (
    <div>
      <ControlComponent
        // 2024-12-11 pdb: passing through props from a wrapper is OK imo
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        options={[
          ...(multiple ? [] : [
            { label: 'Choose one...', value: '' },
          ]),
          ...eligibleResources
            .map(({ state: resource }) => ({
              label: `${resource.display_name} (${resource.key})`,
              value: resource.key,
            })),
        ]}
        className={clsx('config_visual_editor_resource_ref_control', className)}
        labelAction={<span className="nc-t-sub_text_light nc-t-grey_900">{`type: ${resourceType}`}</span>}
      />
      {typeof value === 'string' && value ? (
        <div className="nc-l-mt_utilities_50_mobile">
          <Link
            className="config_visual_editor_resource_ref_control__ref_link"
            to={`/config/entities/${entityId}/resources/${resourceType}|${value}?${searchParams.toString()}`}
            state={{ isRefLink: true }}
          >
            View Resource
          </Link>
        </div>
      ) : null}
    </div>
  );
}
