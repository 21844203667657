import React from 'react';
import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import { DispatchActionProps, ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import { DispatchActionType, EntityConfigResourceDispatchAction, EntityConfigResourceDispatchActionParams } from '../../types/EntityConfig';
import { DispatchActionFormActionClose } from './DispatchActionFormActionClose';
import { DispatchActionFormActionDistributeByServiceArea } from './DispatchActionFormActionDistributeByServiceArea';
import { DispatchActionFormActionEscalate } from './DispatchActionFormActionEscalate';
import { DispatchActionFormActionMultiAckTransitional } from './DispatchActionFormActionMultiAckTransitional';
import { DispatchActionFormRunPolicy } from './DispatchActionFormActionRunPolicy';

const DispatchActionMap: Record<
DispatchActionType,
(props: DispatchActionProps) => React.JSX.Element
> = {
  [DispatchActionType.CLOSE]: DispatchActionFormActionClose,
  [DispatchActionType.DISTRIBUTE_BY_SERVICE_AREA]: DispatchActionFormActionDistributeByServiceArea,
  [DispatchActionType.ESCALATE]: DispatchActionFormActionEscalate,
  [DispatchActionType.MULTI_ACK_TRANSITIONAL]: DispatchActionFormActionMultiAckTransitional,
  [DispatchActionType.RUN_POLICY]: DispatchActionFormRunPolicy,
};

export function DispatchActionForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: dataValue,
  } = resourceData as EntityConfigResourceDispatchAction ?? { data: {} };
  const {
    type: typeValue,
    params: paramsValue,
  } = dataValue ?? {};

  const type: FormField<DispatchActionType | ''> = {
    invalidMessage: '',
    name: 'type',
    validate: () => true,
    validMessage: '',
    value: typeValue ?? '',
  };
  const actionParams: FormField<EntityConfigResourceDispatchActionParams | null> = {
    invalidMessage: '',
    name: 'actionParams',
    validate: () => true,
    validMessage: '',
    value: paramsValue ?? null,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    actionParams,
    type,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        type: formState.type.value,
        params: formState.actionParams.value,
      },
    },
  });

  const TypeComponent = DispatchActionMap[formState.type.value as DispatchActionType] ?? null;

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <NCSelect
        label="Type"
        className="nc-l-mt_200_mobile"
        name={formState.type.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(DispatchActionType)
            .map((actionType) => ({
              label: actionType,
              value: actionType,
            })),
        ]}
        onChange={(event) => onFormChange(formState.type.name, event.target.value)}
        value={formState.type.value}
      />

      <div className="nc-l-mt_200_mobile">
        {TypeComponent ? (
          <TypeComponent
            entityId={entityId}
            onChange={(value) => {
              onFormChange(
                formState.actionParams.name,
                value,
              );
            }}
            value={formState.actionParams.value}
            params={params}
            resources={resources}
          />
        ) : null}
      </div>
    </>
  );
}
