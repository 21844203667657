import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import download from 'downloadjs';
import { stringify } from 'yaml';
import { EditorStoredEntityConfig, useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorLoadDataModal } from '../components/ConfigVisualEditorLoadDataModal';

export function EntitiesLoadDataRoute() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  const {
    loadDataForEntity,
    saveEntityConfig,
    setEntityConfig,
    deleteConfigVersion,
  } = useEntityConfigEditor();

  const [entityData, setEntityData] = useState(loadDataForEntity());
  const handleDelete = (config: EditorStoredEntityConfig) => {
    deleteConfigVersion(config);
    setEntityData(loadDataForEntity());
  };

  const { id: entityId } = useParams();

  const handleDownload = (storedConfig: EditorStoredEntityConfig) => {
    if (!entityId) { return; }
    download(stringify(storedConfig.config, { schema: 'yaml-1.1' }), `${entityId}-${storedConfig.updated}.yml`);
  };

  if (!entityId) { return null; }

  return (
    <ConfigVisualEditorLoadDataModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onCloseFinished={() => navigate(-1)}
      onDownload={handleDownload}
      data={entityData}
      onDelete={handleDelete}
      onSave={() => {
        saveEntityConfig();
        setIsModalOpen(false);
      }}
      onLoad={(config) => {
        setEntityConfig(config);
        setIsModalOpen(false);
      }}
    />
  );
}
