import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import {
  IncidentEmailProcessorProps,
  ResourceFormProps,
  UIIncidentEmailProcessorOhio811NewtinOptions,
  UIIncidentEmailProcessorParams,
  UIIncidentEmailProcessorSouthCarolina811Options,
  UIIncidentEmailProcessorWashington811IrthParams,
} from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields, getParamRefFromId, getResourceRefFromId } from '../../utils/resource-tools';
import { EntityConfigResourceIncidentEmailProcessor, IncidentEmailProcessorType } from '../../types/EntityConfig';
import { IncidentEmailProcessorOhio811Newtin } from './IncidentEmailProcessorOhio811Newtin';
import { IncidentEmailProcessorSouthCarolina811 } from './IncidentEmailProcessorSouthCarolina811';
import { IncidentEmailProcessorWashington811Irth } from './IncidentEmailProcessorWashington811Irth';

const IncidentEmailProcessorParamsMap: Record<
IncidentEmailProcessorType,
(props: IncidentEmailProcessorProps) => React.JSX.Element
> = {
  [IncidentEmailProcessorType.OHIO_811_NEWTIN]: IncidentEmailProcessorOhio811Newtin,
  [IncidentEmailProcessorType.SOUTH_CAROLINA_811]: IncidentEmailProcessorSouthCarolina811,
  [IncidentEmailProcessorType.WASHINGTON_811_IRTH]: IncidentEmailProcessorWashington811Irth,
};

export function IncidentEmailProcessorsForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      processor_type: processorTypeValue,
    },
  } = resourceData as EntityConfigResourceIncidentEmailProcessor ?? { data: {} };
  const processorType: FormField<IncidentEmailProcessorType | ''> = {
    name: 'processorType',
    value: '',
    invalidMessage: '',
    validate: () => true,
    validMessage: processorTypeValue ?? '',
  };
  const processorParams: FormField<UIIncidentEmailProcessorParams | null> = {
    name: 'processorParams',
    value: null,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    processorType,
    processorParams,
  });

  const resolveDataForType = () => {
    if (formState.processorType.value === IncidentEmailProcessorType.OHIO_811_NEWTIN) {
      const options = formState.processorParams
        .value as UIIncidentEmailProcessorOhio811NewtinOptions;
      return {
        options: {
          emergency_etc_hours: options.emergency_etc_hours,
          category_name_template: options.category_name_template,
        },
      };
    }
    if (formState.processorType.value === IncidentEmailProcessorType.SOUTH_CAROLINA_811) {
      const options = formState.processorParams
        .value as UIIncidentEmailProcessorSouthCarolina811Options;
      return {
        options: {
          _workgroup_ref: getResourceRefFromId(options.workgroupId, workgroups),
          _tzname_ref: getParamRefFromId(options.tzNameId, params),
          emergency_statuses: options.emergencyStatuses
            .map(({ emergencyStatus }) => emergencyStatus),
          emergency_etc_hours: options.emergency_etc_hours,
          incident_version: options.incident_version,
          category_name_template: options.category_name_template,
          target_entity_id: options.target_entity_id,
        },
      };
    }
    if (formState.processorType.value === IncidentEmailProcessorType.WASHINGTON_811_IRTH) {
      const options = formState.processorParams
        .value as UIIncidentEmailProcessorWashington811IrthParams;
      return {
        params: {
          _tzname_ref: getParamRefFromId(options.tzNameId, params),
          emergency_etc_hours: options.emergency_etc_hours,
          emergency_classifications: options.emergencyClassifications
            .map(({ emergencyClassification }) => emergencyClassification),
          incident_version: options.incident_version,
          label_template: options.label_template,
        },
      };
    }
    return {};
  };

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        processor_type: formState.processorType.value,
        ...resolveDataForType(),
      },
    },
  });

  const ParamsComponent = IncidentEmailProcessorParamsMap[
    formState.processorType.value as IncidentEmailProcessorType] ?? null;

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <NCSelect
        label="Type"
        className="nc-l-mt_200_mobile"
        name={formState.processorType.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(IncidentEmailProcessorType)
            .map((value) => ({
              label: value,
              value,
            })),
        ]}
        onChange={(event) => onFormChange(formState.processorType.name, event.target.value)}
        value={formState.processorType.value}
      />

      {formState.processorType.value && ParamsComponent ? (
        <ParamsComponent
          onChange={(value) => {
            onFormChange(
              formState.processorParams.name,
              value,
            );
          }}
          params={params}
          resources={resources}
          workgroups={workgroups}
          value={formState.processorParams.value}
        />
      ) : null}
    </>
  );
}
