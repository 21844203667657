import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import yaml from 'yaml';
import {
  EntityConfig,
  EntityConfigModule,
  EntityConfigParam,
  EntityConfigResource,
  EntityConfigResourceType,
  EntityConfigUtilityCategoriesResponse,
} from '../types/EntityConfig';
import { EntityDetail } from '../types/Entity';
import { EditorMode } from '../types/ConfigEditor';
import { sortKeys } from '../utils/config-tools';

export type EditorEntityModule = {
  state: EntityConfigModule;
  id: string;
};

export type EditorEntityParam = {
  state: EntityConfigParam;
  id: string;
};

export type EditorEntityResource = {
  state: EntityConfigResource;
  id: string;
};

export type EditorEntityConfig = {
  keys: string[];
  modules: EditorEntityModule[];
  params: EditorEntityParam[];
  resources: EditorEntityResource[];
};

export type EditorStoredEntityConfig = {
  config: EntityConfig;
  updated: string;
};

export type EntityConfigEditorStore = {
  keys: {
    data: string[],
    add: (key: string) => void,
    remove: (key: string) => void,
  },
  modules: {
    data: EditorEntityModule[],
    add: (module: EntityConfigModule) => void,
    update: (id: string, module: EntityConfigModule) => void,
    remove: (id: string) => void,
  },
  params: {
    data: EditorEntityParam[],
    add: (module: EntityConfigParam) => void,
    update: (id: string, module: EntityConfigParam) => void,
    remove: (id: string) => void,
  },
  resources: {
    data: EditorEntityResource[],
    add: (module: EntityConfigResource) => void,
    update: (id: string, module: EntityConfigResource) => void,
    remove: (id: string, type: EntityConfigResourceType) => void,
  },
  yaml: {
    data: string;
    from: (config: EditorEntityConfig) => string;
    reset: () => void;
    update: (value: string) => void;
  },
  setEntityConfig: (config: EntityConfig) => void;
  loadDataForEntity: () => EditorStoredEntityConfig[];
  saveEntityConfig: (
    options?: { background?: boolean },
  ) => void;
  deleteConfigVersion: (config: EditorStoredEntityConfig) => void;
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  entity: EntityDetail | null;
  editorMode: EditorMode;
  setEditorMode: (mode: EditorMode) => void;
  searchFilter: string;
  setSearchFilter: (filter: string) => void;
  utilityCategories: EntityConfigUtilityCategoriesResponse | null,
  setUtilityCategories: (categories: EntityConfigUtilityCategoriesResponse | null) => void;
};
export const EntityConfigEditorContext = createContext<EntityConfigEditorStore>(
  {} as EntityConfigEditorStore,
);

export const useProvideEntityConfigEditor = (
  {
    entityConfig,
    entityId,
    entity,
  }: {
    entityConfig: EntityConfig | null;
    entityId: string;
    entity: EntityDetail | null;
  },
): EntityConfigEditorStore => {
  const entityConfigToEditorEntityConfig = (config: EntityConfig | null): EditorEntityConfig => {
    if (!config) {
      return {
        keys: [],
        modules: [],
        params: [],
        resources: [],
      };
    }
    return {
      keys: Array.from(new Set([
        ...config.resources.map((resource) => resource.key),
        ...config.params.map((param) => param.key),
      ])),
      modules: config.modules.map((module) => ({ id: module.module_type, state: module })),
      params: config.params.map((param) => ({ id: param.key, state: param })),
      resources: config.resources.map((resource) => ({ id: resource.key, state: resource })),
    };
  };

  const [
    configState,
    setConfigState,
  ] = useState<EditorEntityConfig>(entityConfigToEditorEntityConfig(entityConfig));
  const configToYaml = useCallback((config: EditorEntityConfig) => yaml.stringify({
    modules: config.modules.map(({ state: module }) => module),
    params: config.params.map(({ state: param }) => param),
    resources: config.resources.map(({ state: resource }) => resource),
  }, { schema: 'yaml-1.1' }), []);
  const [yamlValue, setYamlValue] = useState(configToYaml(configState));
  useEffect(() => {
    setYamlValue(configToYaml(configState));
  }, [configState, configToYaml]);

  const [isSaving, setIsSaving] = useState(false);
  const [
    utilityCategories,
    setUtilityCategories,
  ] = useState<EntityConfigUtilityCategoriesResponse | null>(null);
  const [editorMode, setEditorMode] = useState<EditorMode>(EditorMode.VISUAL);
  const [searchFilter, setSearchFilter] = useState('');

  const setEntityConfig = (config: EntityConfig) => {
    setConfigState(entityConfigToEditorEntityConfig(config));
  };

  const STORAGE_KEY = `dplr-icg-${entityId}`;
  const loadDataForEntity = (): EditorStoredEntityConfig[] => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '') ?? [];
    } catch (e) {
      return [];
    }
  };
  const saveEntityConfig = (
    options?: { background?: boolean; },
  ) => {
    const configToSave = {
      modules: configState.modules.map(({ state: module }) => module),
      params: configState.params.map(({ state: param }) => param),
      resources: configState.resources.map(({ state: resource }) => resource),
    };
    const data = loadDataForEntity();
    const lengthLimit = options?.background ? 5 : 4;
    localStorage.setItem(STORAGE_KEY, JSON.stringify([
      ...data.slice(
        data.length > lengthLimit ? data.length - lengthLimit : 0,
        options?.background ? data.length - 1 : data.length,
      ),
      { config: configToSave, updated: new Date().toISOString() },
    ]));
  };
  const deleteConfigVersion = (config: EditorStoredEntityConfig) => {
    const data = loadDataForEntity();
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify(data.filter((c) => c.updated === config.updated)),
    );
  };

  const addKey = (key: string) => {
    setConfigState((state) => ({
      ...state,
      keys: [...state.keys, key],
    }));
  };
  const removeKey = (key: string) => {
    setConfigState((state) => ({
      ...state,
      keys: state.keys.filter((k) => k !== key),
    }));
  };

  const addModule = (module: EntityConfigModule) => {
    setConfigState((state) => ({
      ...state,
      modules: [
        ...state.modules,
        sortKeys({ id: module.module_type, module }) as EditorEntityModule,
      ],
    }));
  };
  const updateModule = (id: string, module: EntityConfigModule) => {
    setConfigState((state) => ({
      ...state,
      modules: state.modules.map((stateModule) => {
        if (stateModule.id !== id) { return stateModule; }
        return sortKeys({ id, module }) as EditorEntityModule;
      }),
    }));
  };
  const removeModule = (id: string) => {
    setConfigState((state) => ({
      ...state,
      modules: state.modules
        .filter((module) => module.id !== id),
    }));
  };

  const addParam = (param: EntityConfigParam) => {
    setConfigState((state) => ({
      ...state,
      params: [
        ...state.params,
        sortKeys({ id: param.key, param }) as EditorEntityParam,
      ],
    }));
  };
  const updateParam = (id: string, param: EntityConfigParam) => {
    setConfigState((state) => ({
      ...state,
      params: state.params.map((stateParam) => {
        if (stateParam.id !== id) { return stateParam; }
        return sortKeys({ id, param }) as EditorEntityParam;
      }),
    }));
  };
  const removeParam = (id: string) => {
    setConfigState((state) => ({
      ...state,
      params: state.params
        .filter((param) => param.id !== id),
    }));
  };

  const addResource = (resource: EntityConfigResource) => {
    setConfigState((state) => ({
      ...state,
      resources: [
        ...state.resources,
        sortKeys({ id: resource.key, resource }) as EditorEntityResource,
      ],
    }));
  };
  const updateResource = (id: string, resource: EntityConfigResource) => {
    setConfigState((state) => ({
      ...state,
      resources: state.resources.map((stateResource) => {
        if (id !== stateResource.id
          || resource.type !== stateResource.state.type) { return stateResource; }
        return sortKeys({ id: resource.key, resource }) as EditorEntityResource;
      }),
    }));
  };
  const removeResource = (id: string, type: EntityConfigResourceType) => {
    setConfigState((state) => ({
      ...state,
      resources: state.resources.filter((resource) => `${resource.state.type}|${resource.id}` !== `${type}|${id}`),
    }));
  };

  return {
    keys: {
      data: configState.keys,
      add: addKey,
      remove: removeKey,
    },
    modules: {
      data: configState.modules,
      add: addModule,
      update: updateModule,
      remove: removeModule,
    },
    params: {
      data: configState.params,
      add: addParam,
      update: updateParam,
      remove: removeParam,
    },
    resources: {
      data: configState.resources,
      add: addResource,
      update: updateResource,
      remove: removeResource,
    },
    yaml: {
      data: yamlValue,
      from: (config: EditorEntityConfig) => configToYaml(config),
      update: setYamlValue,
      reset: () => {
        setYamlValue(configToYaml(configState));
      },
    },
    setEntityConfig,
    loadDataForEntity,
    saveEntityConfig,
    deleteConfigVersion,
    isSaving,
    setIsSaving,
    entity,
    editorMode,
    setEditorMode,
    searchFilter,
    setSearchFilter,
    setUtilityCategories,
    utilityCategories,
  };
};

export const useEntityConfigEditor = () => useContext(EntityConfigEditorContext);
