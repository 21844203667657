import { NCInputText } from '@daupler/nexus-components';

export type ExtentFieldValue = {
  lat_max: string;
  lat_min: string;
  lng_max: string;
  lng_min: string;
};

type ConfigVisualEditorFieldExtentProps = {
  name: string;
  onChange?: (value: ExtentFieldValue) => void;
  value: ExtentFieldValue;
};

export function ConfigVisualEditorFieldExtent({
  name,
  onChange,
  value,
}: ConfigVisualEditorFieldExtentProps) {
  return (
    <div className="nc-flex nc-flex--justify_center nc-flex--column">
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between">
        <div style={{ width: '2rem' }} />
        <NCInputText
          label="Latitude North"
          name={`${name}-lat_max`}
          value={value.lat_max}
          onChange={(event) => {
            onChange?.({
              ...value,
              lat_max: event.target.value,
            });
          }}
        />
        <div style={{ width: '2rem' }} />
      </div>
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mt_100_mobile">
        <NCInputText
          label="Longitude West"
          name={`${name}-lng_min`}
          value={value.lng_min}
          onChange={(event) => {
            onChange?.({
              ...value,
              lng_min: event.target.value,
            });
          }}
        />
        <div style={{ width: '2rem' }} />
        <NCInputText
          label="Longitude East"
          name={`${name}-lng_max`}
          value={value.lng_max}
          onChange={(event) => {
            onChange?.({
              ...value,
              lng_max: event.target.value,
            });
          }}
        />
      </div>
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mt_100_mobile">
        <div style={{ minWidth: '2rem' }} />
        <NCInputText
          label="Latitude South"
          name={`${name}-lat_min`}
          value={value.lat_min}
          onChange={(event) => {
            onChange?.({
              ...value,
              lat_min: event.target.value,
            });
          }}
        />
        <div style={{ minWidth: '2rem' }} />
      </div>
    </div>
  );
}
