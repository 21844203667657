import {
  NCButton,
  NCInputText,
  NCModal,
  NCSelect,
} from '@daupler/nexus-components';
import clsx from 'clsx';
import { useState } from 'react';
import { InputRRuleValue } from './InputRRule';
import InputRRuleForm from './InputRRuleForm';
import {
  addOrUpdateTimezone,
  getOffsetFromIsoString,
  isoStringToLocal,
  timezones,
} from '../../utils/datetime';

function InputRRule({
  className,
  disabled,
  rrule,
  dtstart,
  onChange,
}: {
  className?: string;
  disabled?: boolean;
  rrule?: string;
  dtstart?: string;
  onChange: (value: InputRRuleValue) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [dirtyRrule, setDirtyRrule] = useState(rrule);

  const updateRruleValue = () => {
    setIsEditing(false);
    onChange({
      rrule: dirtyRrule ?? '',
      dtstart: dtstart ?? '',
    });
  };

  const cancelRrule = () => {
    setIsEditing(false);
    setDirtyRrule(rrule);
  };

  const handleDateChange = (date: string) => {
    onChange({
      rrule: rrule ?? '',
      dtstart: addOrUpdateTimezone(date, getOffsetFromIsoString(dtstart ?? '')),
    });
  };
  const handleTimezoneChange = (offset: string) => {
    onChange({
      rrule: rrule ?? '',
      dtstart: addOrUpdateTimezone(isoStringToLocal(dtstart ?? ''), offset),
    });
  };

  return (
    <>
      <div className={clsx('nc-flex nc-flex--gap_1 nc-flex--align_end', className)}>
        <NCInputText
          className="nc-flex_item--3"
          label="Start Date"
          name="dtstart"
          onChange={(event) => handleDateChange(event.target.value)}
          type="datetime-local"
          value={isoStringToLocal(dtstart ?? '')}
          disabled={disabled}
        />
        <NCSelect
          label="Timezone"
          className="nc-flex_item--1"
          name="timezone"
          onChange={(event) => handleTimezoneChange(
            event.target.value,
          )}
          options={[
            { label: 'Choose one...', value: '' },
            ...timezones,
          ]}
          value={getOffsetFromIsoString(dtstart ?? '')}
        />
      </div>
      <input type="hidden" name="rrule" />
      <div className="nc-l-mt_200_mobile">
        <div className="nc-t-body_medium_mobile nc-l-mb_utilities_25_mobile">
          RRule
        </div>
        <div className="nc-t-body_light_mobile nc-l-mb_utilities_25_mobile">
          {rrule}
        </div>
        <NCButton
          onClick={() => setIsEditing(true)}
          disabled={disabled}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          appearance={NCButton.appearances.SOLID}
          color={NCButton.colors.PRIMARY}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
        >
          Edit
        </NCButton>
      </div>
      <NCModal
        isOpen={isEditing}
        closeLabel="Close"
        onClose={() => setIsEditing(false)}
        title="Edit RRule"
        footerChildren={(
          <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-pa_utilities_225_mobile">
            <NCButton
              type="button"
              onClick={cancelRrule}
              appearance={NCButton.appearances.OUTLINE}
              color={NCButton.colors.GREY}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            >
              Close
            </NCButton>
            <NCButton
              type="button"
              onClick={updateRruleValue}
              appearance={NCButton.appearances.SOLID}
              color={NCButton.colors.PRIMARY}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            >
              Update Rule
            </NCButton>
          </div>
        )}
      >
        {isEditing ? (
          <InputRRuleForm
            value={dirtyRrule}
            onChange={setDirtyRrule}
          />
        ) : null}
      </NCModal>
    </>
  );
}

export default InputRRule;
