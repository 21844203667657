import { ChangeEvent, useEffect, useState } from 'react';
import { NCSelect } from '@daupler/nexus-components';
import { getKeyValueFromRRule } from '../../utils/rrule-util';
import { getDaysOfTheWeek, getMonths } from '../../utils/datetime';

function InputRRuleYearlyPos({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [pos, setPos] = useState(getKeyValueFromRRule(value).BYSETPOS ?? '1');
  const updatePos = (event: ChangeEvent<HTMLSelectElement>) => {
    setPos(event.target.value);
  };

  const [month, setMonth] = useState(getKeyValueFromRRule(value).BYMONTH ?? '1');
  const updateMonth = (event: ChangeEvent<HTMLSelectElement>) => {
    setMonth(event.target.value);
  };

  const [day, setDay] = useState('SU');
  const updateDay = (event: ChangeEvent<HTMLSelectElement>) => {
    setDay(event.target.value);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.BYMONTH !== month
      || parsedValue.BYDAY !== day
      || parsedValue.BYSETPOS !== pos
    ) {
      onChange({ BYMONTH: month, BYDAY: day, BYSETPOS: pos });
    }
  }, [day, month, onChange, pos, value]);

  return (
    <div className="nc-flex nc-flex--gap_1">
      <NCSelect
        name="rrule-yearly-day-pos"
        label="Position"
        onChange={updatePos}
        options={[
          {
            label: 'First',
            value: '1',
          },
          {
            label: 'Second',
            value: '2',
          },
          {
            label: 'Third',
            value: '3',
          },
          {
            label: 'Fourth',
            value: '4',
          },
          {
            label: 'Last',
            value: '-1',
          },
        ]}
        value={pos}
      />

      <NCSelect
        name="rrule-yearly-day"
        label="Day"
        onChange={updateDay}
        options={getDaysOfTheWeek().map((weekday) => ({
          label: weekday.label,
          value: weekday.abbr,
        }))}
        value={day.toString()}
      />

      <NCSelect
        name="rrule-yearly-month"
        label="Month"
        onChange={updateMonth}
        options={getMonths().map((monthObject) => ({
          label: monthObject.label,
          value: monthObject.index.toString(),
        }))}
        value={month}
      />
    </div>
  );
}

export default InputRRuleYearlyPos;
