import { EntityConfigModuleType } from '../types/EntityConfig';

export function getIconForModuleType(type: EntityConfigModuleType) {
  switch (type) {
    case EntityConfigModuleType.DAYTIME_DISPATCH:
      return 'fa-map-location-dot';
    case EntityConfigModuleType.ENGAGE:
      return 'fa-mobile';
    case EntityConfigModuleType.NOTIFY:
      return 'fa-bell';
    default:
      return 'fa-square';
  }
}
