import { ExtentFieldValue } from '../components/ConfigVisualEditorFieldExtent';
import { lazy } from './lazy';
import { regionData } from './regionData';

export const countries = lazy(() => regionData.map((country) => ({
  shortCode: country.countryShortCode,
  name: country.countryName,
})));

export const regionsForCountry = (code: string) => {
  const country = regionData.find((region) => region.countryShortCode === code);
  if (country == null) {
    return [];
  }

  return country.regions.map((region) => ({
    name: region.name,
    shortCode: region.shortCode ?? '',
  }));
};

export const extentToGeoJson = (extent: ExtentFieldValue) => JSON.stringify({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [
          [
            [
              parseFloat(extent.lng_min),
              parseFloat(extent.lat_max),
            ],
            [
              parseFloat(extent.lng_max),
              parseFloat(extent.lat_max),
            ],
            [
              parseFloat(extent.lng_max),
              parseFloat(extent.lat_min),
            ],
            [
              parseFloat(extent.lng_min),
              parseFloat(extent.lat_min),
            ],
            [
              parseFloat(extent.lng_min),
              parseFloat(extent.lat_max),
            ],
          ],
        ],
        type: 'Polygon',
      },
    },
  ],
});
