import { NCSelect } from '@daupler/nexus-components';
import { CciDispatchRuleProps } from './resource-form-types';
import { CciDispatchStandardRuleType, CciStandardDispatchRuleParams } from '../../types/EntityConfig';

export function CciDispatchRuleFormStandardRule({
  onChange,
  value,
}: CciDispatchRuleProps) {
  const {
    rule_type: ruleType,
  } = (value ?? {}) as CciStandardDispatchRuleParams;

  return (
    <>
      <p className="nc-l-mt_200_mobile nc-t-sub_text_light_mobile">
        Invoke one of the standard non-customizable Dispatch Rules:
      </p>
      <ul className="impl-listify nc-t-sub_text_light_mobile">
        <li>Critical Sign Dispatch</li>
        <li>Dead Animal Dispatch</li>
        <li>Default Dispatch</li>
        <li>Emergency Only Dispatch</li>
        <li>Facilities Dispatch</li>
        <li>No Dispatch Dispatch</li>
        <li>Shutoff List  Dispatch</li>
        <li>Shutoff List Handle Next Business Day Dispatch</li>
        <li>Traffic Obstruction Dispatch</li>
      </ul>
      <NCSelect
        label="Rule Type"
        name="standard_rule-type"
        className="nc-l-mt_200_mobile"
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(CciDispatchStandardRuleType)
            .map((type) => ({
              label: type,
              value: type,
            })),
        ]}
        onChange={(event) => onChange({
          ...(value as CciStandardDispatchRuleParams),
          rule_type: event.target.value as CciDispatchStandardRuleType,
        })}
        value={ruleType}
      />
    </>
  );
}
