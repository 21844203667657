import { useEffect, useRef } from 'react';
import { EntityConfigAnyResource } from '../types/EntityConfig';
import { OnResourceChange } from '../components/resource-forms/resource-form-types';

export const useSyncResourceForm = ({
  isFormValid,
  onChange,
  onValidate,
  resourceData,
  validateForm,
}: {
  isFormValid: () => boolean;
  onChange: OnResourceChange;
  onValidate: (result: boolean | null, validationCallback: () => boolean) => void;
  resourceData: Omit<EntityConfigAnyResource, 'type' | 'workgroup_internal_name'>;
  validateForm: () => void;
}) => {
  const hasSetCallback = useRef(false);
  const previousResourceData = useRef(JSON.stringify(resourceData));

  useEffect(() => {
    if (hasSetCallback.current) { return; }
    onValidate(
      null,
      () => { validateForm(); return isFormValid(); },
    );
    hasSetCallback.current = true;
  }, [isFormValid, onValidate, validateForm]);
  useEffect(() => {
    if (previousResourceData.current === JSON.stringify(resourceData)) {
      return;
    }
    onChange(resourceData);
    onValidate(isFormValid(), () => { validateForm(); return isFormValid(); });
    previousResourceData.current = JSON.stringify(resourceData);
  }, [isFormValid, onChange, onValidate, resourceData, validateForm]);

  return {};
};
