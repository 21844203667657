export function sortKeys(object: unknown): unknown {
  if (!object) { return object; }
  if (typeof object !== 'object') { return object; }
  if (Array.isArray(object)) {
    return object.map(sortKeys);
  }
  return Object.keys(object)
    .sort()
    .reduce((result, key) => ({
      ...result,
      [key]: sortKeys((object as Record<string, unknown>)[key]),
    }), {});
}
