import {
  NCButton,
  NCWell,
} from '@daupler/nexus-components';
import './InputList.css';

type InputListProps<T> = {
  label: string;
  data: T[];
  onAdd: () => void;
  onRemove: (items: T[]) => void;
  placeholder?: string;
  renderItem: (item: T) => React.JSX.Element;
};

export function InputList<T>({
  children,
  label,
  data,
  onAdd,
  onRemove,
  placeholder,
  renderItem,
}: React.PropsWithChildren<InputListProps<T>>) {
  return (
    <>
      <p className="nc-l-mt_200_mobile nc-l-mb_utilities_25_mobile nc-t-body_medium_mobile">{label}</p>
      {data.length === 0 ? (
        <div className="input_list__list_container">
          <p className="nc-t-sub_text_light_mobile nc-t-grey_700">{placeholder ?? 'No items added yet!'}</p>
        </div>
      ) : (
        <ul>
          {data?.map((item) => (
            <li key={JSON.stringify(item)} className="input_list__list_item_container">
              <div>
                {renderItem(item)}
              </div>
              <div>
                <NCButton
                  appearance={NCButton.appearances.OUTLINE}
                  color={NCButton.colors.ERROR}
                  size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  onClick={() => onRemove(data.filter((datum) => datum !== item))}
                >
                  <i className="fa-solid fa-times" />
                </NCButton>
              </div>
            </li>
          ))}
        </ul>
      )}

      <NCWell>
        {children}
        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          color={NCButton.colors.PRIMARY}
          onClick={onAdd}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          className="nc-l-mt_200_mobile"
        >
          Add Item
        </NCButton>
      </NCWell>
    </>
  );
}
