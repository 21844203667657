import { useState } from 'react';
import './ChecklistControl.css';
import clsx from 'clsx';

type Node<T> = {
  checked?: boolean;
  label: string;
  value: string;
  children: T;
};
export interface NodeList extends Node<NodeList[]> {}

type CheckChildProps = {
  name: string;
  onChange: (tree: Node<NodeList[]>) => void;
  node: Node<NodeList[]>;
};

function CheckChild({
  node,
  name,
  onChange,
}: CheckChildProps) {
  const getChecks = (
    nodeToCheck: Node<NodeList[]>,
  ): boolean[] => {
    if (nodeToCheck.children.length === 0) { return [nodeToCheck.checked ?? false]; }
    return (nodeToCheck.children
      .flatMap((innerChild) => getChecks(innerChild)) as boolean[])
      .filter((v) => v !== undefined);
  };
  const checks = getChecks(node);
  const checkedChildCount = checks.filter((v) => v === true).length;
  const allChildrenChecked = checks.length > 0 && checks.length === checkedChildCount;

  const updateChildren = (
    nodeToUpdate: Node<NodeList[]>,
    value: boolean,
  ): NodeList[] => nodeToUpdate.children.map((child) => ({
    ...child,
    checked: child.children.length === 0 ? value : child.checked,
    children: updateChildren(child, value),
  }));

  const resolveChangeValue = () => {
    if (allChildrenChecked) { return false; }
    return true;
  };

  const handleChange = () => {
    onChange({
      ...node,
      checked: node.children.length === 0 ? !node.checked : node.checked,
      children: updateChildren(node, resolveChangeValue()),
    });
  };

  const resolveCheckboxState = () => {
    if (node.checked || allChildrenChecked) {
      return (<i className="checklist_control__checkbox__icon checklist_control__checkbox__icon--checked fa-regular fa-fw fa-square-check" />);
    }
    if (checkedChildCount > 0) {
      return (<i className="checklist_control__checkbox__icon checklist_control__checkbox__icon--checked fa-regular fa-fw fa-square-minus" />);
    }
    return (<i className="checklist_control__checkbox__icon fa-regular fa-fw fa-square" />);
  };

  const [
    isCollapsed,
    setIsCollapsed,
  ] = useState(checkedChildCount === 0 || allChildrenChecked);

  return (
    <div
      key={node.value}
      className={clsx('checklist_control', {
        'checklist_control--bump_right': node.children.length === 0,
      })}
    >
      {node.children.length ? (
        <button
          type="button"
          className="checklist_control__toggle"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? (
            <i className="fa-solid fa-fw fa-chevron-right" />
          ) : (
            <i className="fa-solid fa-fw fa-chevron-down" />
          )}
          <span className="nc-a11y_hidden">{isCollapsed ? 'Open' : 'Close'}</span>
        </button>
      ) : null}
      <button
        type="button"
        onClick={handleChange}
        className="checklist_control__checkbox"
      >
        {resolveCheckboxState()}
        {' '}
        {node.label}
      </button>
      <div
        className={clsx('checklist_control__children', {
          'checklist_control__children--collapsed': isCollapsed,
        })}
      >
        {node.children.map((childNode) => (
          <CheckChild
            name={name}
            key={childNode.value}
            onChange={onChange}
            node={childNode}
          />
        ))}
      </div>
    </div>
  );
}

type ChecklistControlProps = {
  className?: string;
  label: string;
  name: string;
  onChange: (value: string[]) => void;
  tree: NodeList[];
  value: string[];
};

export function ChecklistControl({
  className,
  label,
  name,
  onChange,
  tree,
  value,
}: ChecklistControlProps) {
  const mergeTree = (): NodeList[] => {
    const mappedValues: string[] = [];
    const resolveTree = (leaf: Node<NodeList[]>): NodeList => {
      if (value.includes(leaf.value)) {
        mappedValues.push(leaf.value);
      }
      return {
        ...leaf,
        checked: value.includes(leaf.value),
        children: leaf.children.map((child) => resolveTree(child)),
      };
    };
    const result = tree.map(resolveTree);
    const unmappedValues = value.filter((val) => !mappedValues.includes(val));
    return [
      ...result,
      ...unmappedValues.map((unmappedValue) => ({
        checked: true,
        label: unmappedValue,
        value: unmappedValue,
        children: [],
      })),
    ];
  };
  const mergedTree = mergeTree();

  const findAndReplaceNode = (
    nodeList: NodeList[],
    node: Node<NodeList[]>,
  ): NodeList[] => nodeList.map((leaf) => {
    if (leaf.value === node.value) {
      return node;
    }
    return {
      ...leaf,
      children: findAndReplaceNode(leaf.children, node),
    };
  });

  const nodeListToStringList = (
    nodeList: NodeList[],
  ): string[] => Array.from(new Set(nodeList.flatMap((node) => [
    node.checked ? node.value : undefined,
    ...nodeListToStringList(node.children),
  ]).filter((v) => !!v) as string[]));

  const handleChange = (node: Node<NodeList[]>) => {
    const updatedTree = findAndReplaceNode(mergedTree, node);
    onChange(nodeListToStringList(updatedTree));
  };

  return (
    <div className={className}>
      <p className="nc-t-body_medium_mobile">{label}</p>
      {mergedTree.length === 0 ? <p className="nc-t-sub_text_light_mobile nc-t-grey_700">No tree to display</p> : null}
      {mergedTree.map((node) => (
        <CheckChild
          key={node.value}
          node={node}
          onChange={handleChange}
          name={name}
        />
      ))}
    </div>
  );
}
