import clsx from 'clsx';
import { NCButton } from '@daupler/nexus-components';
import { EntityConfigModuleType, EntityConfigResourceType } from '../types/EntityConfig';
import './ConfigVisualEditorDrawer.css';
import { getIconForResourceType } from '../utils/resource-tools';
import { getIconForModuleType } from '../utils/module-tools';

type ConfigVisualEditorDrawerProps = {
  onAddModule: () => void;
  onAddResource: () => void;
  onClearSelections: () => void;
  onDrawerSelect: (
    key: string,
    options: { isMultiSelect: boolean },
  ) => void;
  selectedList: string[];
};

export function ConfigVisualEditorDrawer({
  onAddModule,
  onAddResource,
  onClearSelections,
  onDrawerSelect,
  selectedList,
}: ConfigVisualEditorDrawerProps) {
  const handleModuleSelect = (
    type: string,
  ): React.MouseEventHandler<HTMLButtonElement> => (event) => {
    onDrawerSelect(
      `MODULES|${type}`,
      { isMultiSelect: event.shiftKey || event.ctrlKey || event.metaKey },
    );
  };

  const handleResourceSelect = (
    type: string,
  ): React.MouseEventHandler<HTMLButtonElement> => (event) => {
    onDrawerSelect(
      `RESOURCES|${type}`,
      { isMultiSelect: event.shiftKey || event.ctrlKey || event.metaKey },
    );
  };
  return (
    <div className="config_visual_editor_drawer">
      <div className="config_visual_editor_drawer__content">
        <div className="nc-flex nc-flex--justify_end nc-l-mx_200_mobile nc-l-mb_200_mobile">
          <NCButton
            appearance={NCButton.appearances.INVERSE}
            color={NCButton.colors.DARK}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XXS]]}
            onClick={onClearSelections}
          >
            Clear
          </NCButton>
        </div>
        <div className="config_visual_editor_drawer__title">
          <h2>Modules</h2>
          <NCButton
            appearance={NCButton.appearances.INVERSE}
            color={NCButton.colors.DARK}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XXS]]}
            onClick={onAddModule}
          >
            <i className="fa-solid fa-plus fa-fw" />
          </NCButton>
        </div>
        <ul>
          {Object.values(EntityConfigModuleType).map((type) => (
            <li key={type}>
              <button
                type="button"
                className={clsx('config_visual_editor_drawer__list_item', {
                  'config_visual_editor_drawer__list_item--selected': selectedList.includes(`MODULES|${type}`),
                })}
                onClick={handleModuleSelect(type)}
              >
                <i className={clsx('fa-solid', 'fa-fw', getIconForModuleType(type))} />
                {' '}
                {type}
              </button>
            </li>
          ))}
        </ul>

        <div className="config_visual_editor_drawer__title nc-l-mt_200_mobile">
          <h2>Resources</h2>
          <NCButton
            appearance={NCButton.appearances.INVERSE}
            color={NCButton.colors.DARK}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XXS]]}
            onClick={onAddResource}
          >
            <i className="fa-solid fa-plus fa-fw" />
          </NCButton>
        </div>
        <ul>
          {Object.values(EntityConfigResourceType).map((type) => (
            <li key={type}>
              <button
                type="button"
                className={clsx('config_visual_editor_drawer__list_item', {
                  'config_visual_editor_drawer__list_item--selected': selectedList.includes(`RESOURCES|${type}`),
                })}
                onClick={handleResourceSelect(type)}
              >
                <i className={clsx('fa-fw', 'fa-solid', getIconForResourceType(type))} />
                {' '}
                {type}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
