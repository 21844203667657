import { ChangeEvent, useState } from 'react';
import { NCInputRadioGroup } from '@daupler/nexus-components';
import { YearlyRecurrenceType } from './InputRRule.d';
import InputRRuleYearlyMonthday from './InputRRuleYearlyMonthday';
import InputRRuleYearlyPos from './InputRRuleYearlyPos';
import { getKeyValueFromRRule } from '../../utils/rrule-util';

const RecurrenceComponentMap = {
  [YearlyRecurrenceType.MONTHDAY]: InputRRuleYearlyMonthday,
  [YearlyRecurrenceType.POS]: InputRRuleYearlyPos,
};

function InputRRuleYearly({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [type, setType] = useState(() => {
    const rruleKeyValue = getKeyValueFromRRule(value);
    if (rruleKeyValue.BYSETPOS) {
      return YearlyRecurrenceType.POS;
    }
    return YearlyRecurrenceType.MONTHDAY;
  });
  const updateType = (event: ChangeEvent<HTMLInputElement>) => {
    setType(event.target.id as YearlyRecurrenceType);
  };

  const handleRecurrenceChange = (keyValue: Record<string, string>) => {
    onChange(keyValue);
  };

  const RecurrenceComponent = RecurrenceComponentMap[type];

  return (
    <>
      <NCInputRadioGroup
        name="rrule-yearly-recurrence-type"
        label="Yearly Recurrence Type"
        onChange={updateType}
        value={type}
        options={[
          {
            id: YearlyRecurrenceType.MONTHDAY,
            label: 'On',
          },
          {
            id: YearlyRecurrenceType.POS,
            label: 'On the',
          },
        ]}
      />

      <RecurrenceComponent onChange={handleRecurrenceChange} value={value} />
    </>
  );
}

export default InputRRuleYearly;
