import { useState } from 'react';
import { NCLoadingIndicator } from '@daupler/nexus-components';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ConfigVisualEditorResourceModal } from '../components/ConfigVisualEditorResourceModal';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResource } from '../types/EntityConfig';
import { getResourceReferencesByType } from '../utils/resource-tools';

export function EntitiesEditResourceRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    modules,
    params,
    resources,
    saveEntityConfig,
    setIsSaving,
    entity,
    utilityCategories,
  } = useEntityConfigEditor();

  const STORAGE_KEY = `dplr-icg-${entity?.id}-wip`;
  const getWipResources = () => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '');
    } catch {
      return {};
    }
  };

  const { resourceId } = useParams();
  const [resourceType, key] = resourceId?.split('|') ?? [];
  const handleSubmit = async (resource: EntityConfigResource) => {
    resources.update(key, resource);
    setIsSaving(true);
    saveEntityConfig({ background: true });
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...getWipResources(), [key]: null }));
    setIsSaving(false);
  };

  const resource = resources.data
    .find(({ id, state: { type } }) => id === key && resourceType === type);
  const copyResource = () => {
    if (!resourceId || !entity) { return; }
    searchParams.append('resource-type', resource?.state.type ?? '');
    navigate(
      `/config/entities/${entity.id}/add-resource?${searchParams.toString()}`,
      {
        state: {
          resourceData: {
            id: undefined,
            resource: {
              ...resource?.state ?? {},
              key: undefined,
            },
          },
        },
      },
    );
  };

  const handleExit = () => {
    if (!entity) { return; }
    if (location.state?.isRefLink || location.state?.wasAppLink) {
      navigate(-1);
    } else {
      navigate(`/config/entities/${entity.id}?${searchParams.toString()}`);
    }
  };

  const handleProgressSave = (values: unknown) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...getWipResources(), [key]: values }));
  };
  const [isAbandoningChanges, setIsAbandoningChanges] = useState(false);
  const handleAbandonChanges = () => {
    setIsAbandoningChanges(true);
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...getWipResources(), [key]: undefined }));
    setTimeout(() => setIsAbandoningChanges(false), 125);
  };

  if (!entity || !resource) { return null; }
  if (isAbandoningChanges) { return <NCLoadingIndicator blockUi label="Abandoning changes..." />; }
  return (
    <ConfigVisualEditorResourceModal
      key={resourceId}
      copyResource={copyResource}
      entityId={entity?.id ?? ''}
      onAbandonChanges={handleAbandonChanges}
      onBack={handleExit}
      onClose={handleExit}
      onSaveProgress={handleProgressSave}
      onSubmit={handleSubmit}
      params={params.data}
      resource={resource}
      resources={resources.data}
      utilityCategories={utilityCategories}
      wipResource={getWipResources()[key]}
      resourceReferences={getResourceReferencesByType(
        resource.state,
        resources.data,
        modules.data,
      )}
    />
  );
}
