import { NCButton, NCModal } from '@daupler/nexus-components';
import { useState } from 'react';

type ConfigVisualEditorUnsavedWarningProps = {
  className?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export function ConfigVisualEditorUnsavedWarning({
  className,
  onCancel,
  onConfirm,
}: ConfigVisualEditorUnsavedWarningProps) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <NCModal
      className={className}
      closeLabel="Close"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onCloseFinished={onCancel}
      title="Are you sure?"
      footerChildren={(
        <div className="nc-flex nc-flex--align_items nc-flex--justify_between nc-l-pa_utilities_225_mobile">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onCancel}
          >
            Keep Editing
          </NCButton>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            color={NCButton.colors.PRIMARY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onConfirm}
          >
            Abandon Changes
          </NCButton>
        </div>
      )}
    >
      <p className="nc-t-grey_900 nc-t-body_regular_mobile">
        Changes you have made will be lost.
      </p>
    </NCModal>
  );
}
