import { NCButton, NCLabel } from '@daupler/nexus-components';
import './ConfigVisualEditorResource.css';
import clsx from 'clsx';

type ConfigVisualEditorResourceProps = {
  displayName: string;
  hasChanges?: boolean;
  icon: string;
  onEdit: () => void;
  onFilter: () => void;
  onRemove: () => void;
  referenceCount: number;
  resourceKey: string;
  type: string;
};

export function ConfigVisualEditorResource({
  displayName,
  hasChanges,
  icon,
  onEdit,
  onFilter,
  onRemove,
  referenceCount,
  resourceKey,
  type,
}: ConfigVisualEditorResourceProps) {
  return (
    <div className="config_visual_editor_resource">
      <div className="nc-flex nc-flex--align_center">
        <NCLabel label="resource" size={NCLabel.sizes.SM} color={NCLabel.colors.PRIMARY} />
        {' '}
        <span className="nc-t-sub_text_light_mobile nc-l-ml_100_mobile">
          <i
            className={clsx('fa-solid', 'fa-fw', icon, {
              'config_visual_editor_resource--unsaved': hasChanges,
            })}
          />
          {' '}
          {type}
        </span>
      </div>
      <div className="nc-t-body_medium_mobile nc-l-mt_100_mobile">
        {displayName}
      </div>
      <div className="nc-t-info_text_light_mobile">
        {`(${resourceKey})`}
        <br />
        <NCButton
          appearance={NCButton.appearances.LINK}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={onFilter}
        >
          {`Used ${referenceCount} times.`}
        </NCButton>
      </div>

      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mt_200_mobile">
        <NCButton
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          color={NCButton.colors.PRIMARY}
          appearance={NCButton.appearances.INVERSE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
          onClick={onEdit}
        >
          <i className="fa-light fa-pencil" />
          {' '}
          Edit
        </NCButton>

        <NCButton
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          color={NCButton.colors.ERROR}
          appearance={NCButton.appearances.OUTLINE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
          disabled={referenceCount !== 0}
          onClick={onRemove}
        >
          <i className="fa-light fa-trash" />
          {' '}
          Remove
        </NCButton>
      </div>
    </div>
  );
}
