import { CciDispatchRuleProps } from './resource-form-types';
import { CciRedirectParams, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function CciDispatchRuleFormNeverRedirect({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _redirect_to_ref_list: refList } = (value ?? {}) as CciRedirectParams;
  return (
    <>
      <p className="nc-l-mt_200_mobile nc-t-sub_text_light_mobile">Do not dispatch the Incident. Direct the caller to call a different number.</p>
      <ConfigVisualEditorParamControl
        label="Redirects"
        className="nc-l-mt_200_mobile"
        multiple
        name="redirect-phone"
        params={params}
        paramType={EntityConfigParamType.PHONE_NUMBER}
        onChange={(event) => {
          const values = Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => option.value);
          onChange({
            ...(value as CciRedirectParams),
            _redirect_to_ref_list: values.map((val) => ({
              key: val,
              type: EntityConfigParamType.PHONE_NUMBER,
            })),
          });
        }}
        value={(refList ?? []).map((ref) => ref.key)}
      />
    </>
  );
}
