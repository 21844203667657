export const getDayHours = () => new Array(24)
  .fill(undefined)
  .map((_, i) => `${i.toString().padStart(2, '0')}:00`);

export const getMonths = (locale: string = 'en-US'): { label: string; index: number; }[] => {
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    const date = new Date();
    date.setDate(1);
    date.setMonth(i);
    months.push({
      label: date.toLocaleString(locale, { month: 'long' }),
      index: i + 1,
    });
  }
  return months;
};

export const getDaysOfTheWeek = (locale: string = 'en-US'): { label: string; abbr: string; }[] => {
  const { format } = new Intl.DateTimeFormat(locale, { weekday: 'long' });
  const { format: formatAbbr } = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
  const days = [];
  for (let i = 0; i < 7; i += 1) {
    days.push({
      label: format(new Date(Date.UTC(2021, 5, i))),
      abbr: formatAbbr(new Date(Date.UTC(2021, 5, i))).substr(0, 2).toUpperCase(),
    });
  }
  return days;
};

export const getDayIndexFromAbbr = (dayAbbr: string, locale: string = 'en-US') => {
  const days = getDaysOfTheWeek(locale);
  return days.findIndex((day) => day.abbr === dayAbbr);
};

export const getDaysInMonth = (month: number, year: number): number => {
  const date = new Date(year, month, 0);
  return date.getDate();
};

export const getCurrentYear = (): number => new Date().getFullYear();

export const isoStringToInstant = (isoString: string) => {
  const parts = isoString.split('T');
  return parts[0];
};

const getPartsFromIsoString = (isoString: string) => {
  const [
    date,
    timeAndTimezone,
  ] = isoString.split('T');
  const [
    year,
    month,
    day,
  ] = date?.split('-') ?? ['', '', ''];
  const [
    time,
    offsetOrZ,
  ] = timeAndTimezone?.split('-') ?? ['', ''];
  return {
    year,
    month,
    day,
    time,
    offsetOrZ,
  };
};

export const isoStringToLocal = (isoString: string) => {
  const {
    year,
    month,
    day,
    time,
  } = getPartsFromIsoString(isoString);
  return `${year}-${month}-${day}T${time}`;
};

export const getDayAbbrForISODate = (isoString: string, locale: string = 'en-US') => {
  const days = getDaysOfTheWeek(locale);
  return days[new Date(isoString).getDay()];
};

export const getOffsetFromIsoString = (isoString: string) => {
  const { offsetOrZ } = getPartsFromIsoString(isoString);
  return offsetOrZ.toUpperCase() === 'Z'
    ? ((new Date().getTimezoneOffset() / 60) * 100).toString()
    : offsetOrZ;
};
export const addOrUpdateTimezone = (isoString: string, offset: string) => {
  const {
    day,
    month,
    year,
    time,
  } = getPartsFromIsoString(isoString);
  if (!year) {
    return `0000-00-00T${time}-${offset}`;
  }
  return `${year}-${month}-${day}T${time}-${offset}`;
};

export const timezones = [
  { label: 'Eastern (GMT-5)', value: '0500' },
  { label: 'Central (GMT-6)', value: '0600' },
  { label: 'Mountain (GMT-7)', value: '0700' },
  { label: 'Pacific (GMT-8)', value: '0800' },
  { label: 'Alaska (GMT-9)', value: '0900' },
  { label: 'Hawaii-Aleutian (GMT-10)', value: '1000' },
];
