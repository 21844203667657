import { NCSelect } from '@daupler/nexus-components';
import { ComponentProps } from 'react';
import clsx from 'clsx';
import {
  EntityConfigParam,
  EntityConfigParamImageValue,
  EntityConfigParamPhoneNumberValue,
  EntityConfigParamStringValue,
  EntityConfigParamType,
} from '../types/EntityConfig';
import { EditorEntityParam } from '../hooks/useEntityConfigEditor';
import { InputMultiSelectControl } from './InputMultiSelectControl';

type ConfigVisualEditorParamControlProps = Omit<ComponentProps<typeof NCSelect>, 'options'> & {
  params: EditorEntityParam[];
  paramType: EntityConfigParamType;
};

export function ConfigVisualEditorParamControl(props: ConfigVisualEditorParamControlProps) {
  const {
    className,
    multiple,
    params,
    paramType,
  } = props;

  const getParamDisplayValue = (param: EntityConfigParam) => {
    const { value } = param;
    switch (paramType) {
      case EntityConfigParamType.EXTENT:
        return '';
      case EntityConfigParamType.IMAGE:
        return (value as EntityConfigParamImageValue).filename ?? '';
      case EntityConfigParamType.JSON:
        return '';
      case EntityConfigParamType.PHONE_NUMBER:
        return (value as EntityConfigParamPhoneNumberValue).phone_number;
      case EntityConfigParamType.STRING:
        return (value as EntityConfigParamStringValue).text;
      default:
        return '';
    }
  };

  const eligibleParams = params
    .filter(({ state: { type } }) => paramType === type);

  const ControlComponent = multiple ? InputMultiSelectControl : NCSelect;

  return (
    <ControlComponent
      // 2024-12-11 pdb: passing through props from a wrapper is OK imo
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={clsx('config_visual_editor_param_control', className)}
      labelAction={<span className="nc-t-sub_text_light nc-t-grey_900">{`type: ${paramType}`}</span>}
      options={[
        ...(multiple ? [] : [
          { label: 'Choose one...', value: '' },
        ]),
        ...eligibleParams
          .map(({ id, state: param }) => ({
            label: `${param.key}: ${getParamDisplayValue(param)}`,
            value: id,
          })),
      ]}
    />
  );
}
