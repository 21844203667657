import { NCInputCheckbox, NCInputText } from '@daupler/nexus-components';
import { ConfigVisualEditorFieldPhone } from './ConfigVisualEditorFieldPhone';
import { ConfigVisualEditorFieldJson } from './ConfigVisualEditorFieldJson';
import { ConfigVisualEditorFieldExtent } from './ConfigVisualEditorFieldExtent';
import { ConfigVisualEditorFieldImage } from './ConfigVisualEditorFieldImage';
import './ConfigVisualEditorField.css';
import { ImageStaticMap } from './ImageStaticMap';
import { GeoJson, getCenterFromPaths, getPathsFromGeoJson } from '../utils/location-tools';
import { Extent } from '../types/EntityConfig';
import { getJsonValueForDisplay, getJsonValueForField } from '../utils/param-tools';

type ConfigVisualEditorFieldProps = {
  id: string;
  fieldName: string;
  fieldValue: unknown;
  onChange?: (value: unknown) => void;
  preview?: boolean;
  type?: string;
};

export function ConfigVisualEditorField({
  id,
  fieldName,
  fieldValue,
  onChange,
  preview,
  type,
}: ConfigVisualEditorFieldProps) {
  const name = `${id}-${fieldName}`;

  if (type === 'string') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">{(fieldValue as { text: string }).text}</div>
      );
    }
    return (
      <NCInputText
        label={fieldName}
        name={name}
        value={(fieldValue as { text: string; }).text}
        multiline
        rows={3}
        onChange={(event) => {
          onChange?.({ text: event.target.value });
        }}
      />
    );
  }
  if (type === 'phone_number') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">
          {(fieldValue as { phone_number: string; display_name: string; }).display_name}
          <br />
          {(fieldValue as { phone_number: string; display_name: string; }).phone_number}
        </div>
      );
    }
    return (
      <ConfigVisualEditorFieldPhone
        name={fieldName}
        value={fieldValue as { phone_number: string; display_name: string; } ?? {}}
        onChange={(v) => {
          onChange?.(v);
        }}
      />
    );
  }
  if (type === 'json') {
    if (preview) {
      const jsonValue = getJsonValueForDisplay(fieldValue as string);
      const geoJsonTypes = [
        'FeatureCollection',
        'Feature',
        'Point',
        'LineString',
        'Polygon',
        'MultiPoint',
        'MultiLineString',
        'MultiPolygon',
      ];
      if (geoJsonTypes.includes((jsonValue.data as unknown as GeoJson)?.type)) {
        const paths = getPathsFromGeoJson(jsonValue.data as unknown as GeoJson);
        const center = getCenterFromPaths(paths);
        if (!center) { return <p>Could not calculate center from paths. Please check them.</p>; }
        return (
          <ImageStaticMap
            lat={center[1]}
            lng={center[0]}
            paths={paths}
            dims={{ width: 308, height: 308 }}
          />
        );
      }
      return (
        <div className="config_visual_editor_field__preview">Preview Unavailable</div>
      );
    }
    return (
      <ConfigVisualEditorFieldJson
        label={fieldName}
        name={fieldName}
        value={getJsonValueForField(fieldValue as string)}
        onChange={(event) => {
          onChange?.(event.target.value);
        }}
      />
    );
  }
  if (type === 'extent') {
    const {
      lat_max: latMax,
      lng_max: lngMax,
      lat_min: latMin,
      lng_min: lngMin,
    } = (fieldValue as Extent);
    if (preview) {
      const paths: [number, number][][] = [[
        [latMin, lngMax],
        [latMin, lngMin],
        [latMax, lngMin],
        [latMax, lngMax],
        [latMin, lngMax],
      ]];
      const center = getCenterFromPaths(paths);
      if (!center) {
        return (
          <p>Could not calculate center from paths. Please check them.</p>
        );
      }
      return (
        <ImageStaticMap
          lat={center[0]}
          lng={center[1]}
          paths={paths}
          dims={{ width: 308, height: 308 }}
        />
      );
    }
    return (
      <ConfigVisualEditorFieldExtent
        name={fieldName}
        value={{
          lat_max: latMax?.toString() ?? '',
          lat_min: latMin?.toString() ?? '',
          lng_max: lngMax?.toString() ?? '',
          lng_min: lngMin?.toString() ?? '',
        }}
        onChange={(v) => {
          onChange?.(v);
        }}
      />
    );
  }
  if (type === 'image') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">
          {(fieldValue as { public_url: string })?.public_url}
        </div>
      );
    }
    return (
      <ConfigVisualEditorFieldImage
        src={(fieldValue as { public_url: string })?.public_url}
        onChange={(v) => {
          onChange?.({ public_url: v });
        }}
      />
    );
  }

  if (typeof fieldValue === 'string') {
    if (preview) {
      return (
        <p className="nc-t-body_light_mobile">
          {fieldName}
          {': '}
          {`${fieldValue}`}
        </p>
      );
    }
    return (
      <NCInputText
        label={fieldName}
        name={name}
        value={fieldValue as string}
        multiline
        rows={3}
      />
    );
  }
  if (typeof fieldValue === 'boolean') {
    if (preview) {
      return (
        <p className="nc-t-body_light_mobile">
          {fieldName}
          {': '}
          {`${fieldValue}`}
        </p>
      );
    }
    return (
      <NCInputCheckbox
        label={fieldName}
        name={name}
        checked={fieldValue}
      />
    );
  }
  return null;
}
