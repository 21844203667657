import {
  PropsWithChildren, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import yaml from 'yaml';
import {
  NCToast,
} from '@daupler/nexus-components';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { useToast } from '../hooks/useToasts';
import { ConfigVisualEditorHeader } from './ConfigVisualEditorHeader';
import { ConfigVisualEditorObjectsMenu } from './ConfigVisualEditorObjectsMenu';
import './ConfigVisualEditorLayout.css';
import { EditorMode } from '../types/ConfigEditor';
import { ConfigVisualEditorUnsavedWarning } from './ConfigVisualEditorUnsavedWarning';
import { ConfigVisualEditorDrawer } from './ConfigVisualEditorDrawer';

export function ConfigVisualEditorLayout({
  children,
}: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTrayOpen, setIsTrayOpen] = useState(searchParams.get('objectsMenu') === 'true');
  useEffect(() => {
    if (searchParams.get('objectsMenu') === 'true' && !isTrayOpen) {
      setIsTrayOpen(true);
    } else if (searchParams.get('objectsMenu') === 'false' && isTrayOpen) {
      setIsTrayOpen(false);
    }
  }, [isTrayOpen, searchParams]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(searchParams.get('drawer') === 'true');
  useEffect(() => {
    if (searchParams.get('drawer') === 'true' && !isDrawerOpen) {
      setIsDrawerOpen(true);
    } else if (searchParams.get('drawer') === 'false' && isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [isDrawerOpen, searchParams, setSearchParams]);

  const {
    entity,
    keys,
    modules,
    params,
    resources,
    yaml: yamlConfig,
    saveEntityConfig,
    isSaving,
    setIsSaving,
    editorMode,
    setEditorMode,
    setSearchFilter,
    searchFilter,
  } = useEntityConfigEditor();

  const { addToast } = useToast();
  const saveConfig = () => {
    if (!entity) { return; }
    setIsSaving(true);
    saveEntityConfig();
    addToast({
      body: 'Saved config to local storage',
      id: 'yaml-foreground-save',
      subject: 'Configuration Saved',
      type: NCToast.style.SUCCESS,
    });
    setIsSaving(false);
  };

  const [shouldNagOnCodeChange, setShouldNagOnCodeChange] = useState(false);
  const prevEditorMode = useRef(editorMode);
  useEffect(() => {
    const currentConfigValue = yamlConfig.from({
      keys: keys.data,
      modules: modules.data,
      params: params.data,
      resources: resources.data,
    });
    if (editorMode === EditorMode.YAML || editorMode === prevEditorMode.current) {
      prevEditorMode.current = editorMode;
      return;
    }
    if (currentConfigValue === yaml.stringify(yaml.parse(yamlConfig.data), { schema: 'yaml-1.1' })) {
      prevEditorMode.current = editorMode;
      return;
    }
    setShouldNagOnCodeChange(true);
    prevEditorMode.current = editorMode;
    setEditorMode(EditorMode.YAML);
  }, [editorMode, keys.data, modules.data, params.data, resources.data, setEditorMode, yamlConfig]);

  const navigate = useNavigate();

  const handleDrawerSelection = (
    key: string,
    options: { isMultiSelect: boolean },
  ) => {
    if (!entity) { return; }
    const updatedParams = new URLSearchParams(searchParams);
    const currentValue = updatedParams.get('type-filter')?.split(',') ?? [];
    const isTypeAlreadySelected = currentValue.includes(key) ?? false;
    if (options.isMultiSelect) {
      updatedParams.set(
        'type-filter',
        isTypeAlreadySelected
          ? currentValue.filter((v) => v !== key).join(',')
          : [...currentValue, key].join(','),
      );
    } else {
      updatedParams.set(
        'type-filter',
        isTypeAlreadySelected ? '' : key,
      );
    }
    navigate(`/config/entities/${entity.id}/?${updatedParams.toString()}`);
  };

  return (
    <>
      <div
        className={clsx('entities_id_route', {
          'entities_id_route--tray': isTrayOpen,
          'entities_id_route--drawer': isDrawerOpen,
        })}
      >
        <div className="entities_id_route__header">
          <ConfigVisualEditorHeader
            entity={entity}
            editorMode={editorMode}
            onDrawerClick={() => {
              const newParams = new URLSearchParams(searchParams.toString());
              newParams.set('drawer', (!isDrawerOpen).toString());
              setSearchParams(newParams);
            }}
            onEditorModeChange={setEditorMode}
            onSearchFilterUpdate={setSearchFilter}
            searchFilter={searchFilter}
            isDrawerOpen={isDrawerOpen}
            isObjectsMenuOpen={isTrayOpen}
            onObjectsMenuClick={() => {
              const newParams = new URLSearchParams(searchParams.toString());
              newParams.set('objectsMenu', (!isTrayOpen).toString());
              setSearchParams(newParams);
            }}
            onSave={saveConfig}
            isSaving={isSaving}
          />
        </div>

        {isDrawerOpen ? (
          <div className="entities_id_route__drawer">
            <ConfigVisualEditorDrawer
              onAddModule={() => {
                navigate(
                  `add-module?${searchParams.toString()}`,
                  {
                    state: { wasAppLink: true },
                  },
                );
              }}
              onAddResource={() => {
                navigate(
                  `add-resource?${searchParams.toString()}`,
                  {
                    state: { wasAppLink: true },
                  },
                );
              }}
              onClearSelections={() => {
                const newParams = new URLSearchParams(searchParams);
                newParams.set('type-filter', '');
                setSearchParams(newParams);
              }}
              onDrawerSelect={handleDrawerSelection}
              selectedList={searchParams.get('type-filter')?.split(',') ?? []}
            />
          </div>
        ) : null}

        <div className="entities_id_route__workspace">
          {children}
        </div>

        {isTrayOpen ? (
          <div className="entities_id_route__tray">
            <ConfigVisualEditorObjectsMenu
              onParamAdd={params.add}
              onParamFilter={(paramKey) => setSearchFilter(paramKey)}
              onParamRemove={params.remove}
              onParamUpdate={params.update}
              resources={resources.data}
              params={params.data}
              modules={modules.data}
            />
          </div>
        ) : null}
      </div>
      {shouldNagOnCodeChange ? (
        <ConfigVisualEditorUnsavedWarning
          onCancel={() => { setShouldNagOnCodeChange(false); }}
          onConfirm={() => {
            yamlConfig.reset();
            setShouldNagOnCodeChange(false);
            setEditorMode(EditorMode.VISUAL);
          }}
        />
      ) : null}
    </>
  );
}
