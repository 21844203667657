import { useState } from 'react';
import { NCInputCheckbox, NCInputText } from '@daupler/nexus-components';
import {
  EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParams,
  EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParamsRoute,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { InputList } from '../InputList';
import { DispatchActionProps } from './resource-form-types';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function DispatchActionFormActionDistributeByServiceArea({
  entityId,
  onChange,
  value,
  resources,
}: DispatchActionProps) {
  const getEmptyRoute = () => ({
    _dispatch_policy_ref: undefined,
    _service_area_ref: undefined,
    _workgroup_ref: undefined,
    display_name: '',
    match_pattern: '',
    is_fallback: false,
  });
  const [
    newRoute,
    setNewRoute,
  ] = useState<EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParamsRoute>(
    getEmptyRoute(),
  );

  const {
    _dispatch_policy_ref: dispatchPolicyRef,
    _service_area_ref: serviceAreaRef,
    _workgroup_ref: workgroupRef,
  } = newRoute;

  const {
    routes = [],
  } = value as EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParams ?? {};
  return (
    <InputList<EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParamsRoute>
      data={routes}
      label="Routes"
      onAdd={() => {
        onChange({
          service_area_options: {},
          routes: [
            ...routes,
            newRoute,
          ],
        });
      }}
      onRemove={(data) => {
        onChange({
          ...value as EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParams,
          routes: data,
        });
      }}
      renderItem={(route) => (
        <ul>
          <li>{route.display_name}</li>
          <li>
            Is fallback:
            {' '}
            {`${route.is_fallback}`}
          </li>
          <li>
            Match pattern:
            {' '}
            {route.match_pattern}
          </li>
          <li>
            Dispatch Policy:
            {' '}
            {resources.find(({ state: resource }) => {
              const { _dispatch_policy_ref: ref } = route;
              return ref?.key === resource.key
                && resource.type === EntityConfigResourceType.DISPATCH_POLICIES;
            })?.state.display_name}
          </li>
          <li>
            Service Area:
            {' '}
            {resources.find(({ state: resource }) => {
              const { _service_area_ref: ref } = route;
              return ref?.key === resource.key
                && resource.type === EntityConfigResourceType.SERVICE_AREAS;
            })?.state.display_name}
          </li>
          <li>
            Workgroup:
            {' '}
            {resources.find(({ state: resource }) => {
              const { _workgroup_ref: ref } = route;
              return ref?.key === resource.key
                && resource.type === EntityConfigResourceType.WORKGROUPS;
            })?.state.display_name}
          </li>
        </ul>
      )}
      placeholder="No Routes here yet!"
    >
      <ConfigVisualEditorResourceRefControl
        label="Dispatch Policy"
        name="distribute_by_service_area-dispatch_policy"
        resources={resources}
        resourceType={EntityConfigResourceType.DISPATCH_POLICIES}
        entityId={entityId}
        value={dispatchPolicyRef?.key}
        onChange={(event) => setNewRoute({
          ...newRoute,
          _dispatch_policy_ref: {
            key: event.target.value,
            type: EntityConfigResourceType.DISPATCH_POLICIES,
          },
        })}
      />
      <ConfigVisualEditorResourceRefControl
        label="Service Area"
        name="distribute_by_service_area-service_area"
        resources={resources}
        resourceType={EntityConfigResourceType.SERVICE_AREAS}
        entityId={entityId}
        value={serviceAreaRef?.key ?? ''}
        onChange={(event) => setNewRoute({
          ...newRoute,
          _service_area_ref: {
            key: event.target.value,
            type: EntityConfigResourceType.SERVICE_AREAS,
          },
        })}
        className="nc-l-mt_200_mobile"
      />
      <ConfigVisualEditorResourceRefControl
        label="Workgroup"
        name="distribute_by_service_area-workgroup"
        resources={resources}
        resourceType={EntityConfigResourceType.WORKGROUPS}
        entityId={entityId}
        value={workgroupRef?.key ?? ''}
        onChange={(event) => setNewRoute({
          ...newRoute,
          _workgroup_ref: {
            key: event.target.value,
            type: EntityConfigResourceType.WORKGROUPS,
          },
        })}
        className="nc-l-mt_200_mobile"
      />
      <NCInputText
        label="Display Name"
        name="distribute_by_service_area-display_name"
        value={newRoute.display_name}
        onChange={(event) => setNewRoute({
          ...newRoute,
          display_name: event.target.value,
        })}
        className="nc-l-mt_200_mobile"
      />
      <NCInputText
        label="Match Pattern"
        name="distribute_by_service_area-display_name"
        value={newRoute.match_pattern}
        onChange={(event) => setNewRoute({
          ...newRoute,
          match_pattern: event.target.value,
        })}
        className="nc-l-mt_200_mobile"
      />
      <NCInputCheckbox
        className="nc-l-mt_200_mobile"
        label="Is Fallback?"
        name="distribute_by_service_area-is_fallback"
        checked={newRoute.is_fallback}
        onChange={() => setNewRoute({
          ...newRoute,
          is_fallback: !newRoute.is_fallback,
        })}
      />
    </InputList>
  );
}
