import { NCInputText } from '@daupler/nexus-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { getKeyValueFromRRule } from '../../utils/rrule-util';

function InputRRuleDaily({ onChange, value }: {
  onChange: (keyValue: Record<string, string>) => void;
  value: string;
}) {
  const [recurrenceInterval, setRecurrenceInterval] = useState(() => {
    const rruleKeyVal = getKeyValueFromRRule(value);
    return rruleKeyVal.INTERVAL || '1';
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRecurrenceInterval(event.target.value);
  };

  useEffect(() => {
    const parsedValue = getKeyValueFromRRule(value);
    if (parsedValue.INTERVAL !== recurrenceInterval) {
      onChange({ INTERVAL: recurrenceInterval });
    }
  }, [onChange, recurrenceInterval, value]);

  return (
    <NCInputText
      name="rrule-daily-recurrence"
      label="Every N Days"
      type="number"
      min={1}
      onChange={handleChange}
      step={1}
      value={recurrenceInterval}
    />
  );
}

export default InputRRuleDaily;
