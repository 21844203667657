import { FormField } from '@daupler/nexus-components';
import {
  EntityConfigModule,
  EntityConfigParam,
  EntityConfigRef,
  EntityConfigResource,
  EntityConfigResourceType,
  EntityConfigUtilityCategoriesResponse,
} from '../types/EntityConfig';
import { EditorEntityModule, EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { NodeList } from '../components/ChecklistControl';

type GetBaseResourceFormFieldsOptions = {
  isResourceReferenced: boolean;
  resourceData: Omit<EntityConfigResource, 'type'> | null;
  resourceType: EntityConfigResourceType;
  resources: EditorEntityResource[];
};

export function getBaseResourceFormFields({
  isResourceReferenced,
  resourceData,
  resourceType,
  resources,
}: GetBaseResourceFormFieldsOptions) {
  const { _workgroup_ref: workgroupRefValue } = resourceData ?? {};

  const key: FormField<string> = {
    invalidMessage: 'Keys must be unique',
    name: 'key',
    validate: (value, state) => {
      if (state.key.initialValue === value) { return true; }
      const matches = resources.filter((r) => r.state.type === resourceType
        && r.state.key === value);
      if (!isResourceReferenced && matches.length <= 1) { return true; }
      if (matches.length > 0) { return false; }
      return true;
    },
    validMessage: '',
    value: resourceData?.key ?? '',
    initialValue: isResourceReferenced ? resourceData?.key ?? '' : '',
  };
  const displayName: FormField<string> = {
    invalidMessage: 'Display Name is required',
    name: 'displayName',
    validate: (value) => !!value,
    validMessage: '',
    value: resourceData?.display_name ?? '',
  };
  const workgroupRef: FormField<EntityConfigRef | undefined> = {
    invalidMessage: '',
    name: 'workgroupRef',
    validate: () => true,
    validMessage: '',
    value: workgroupRefValue ?? undefined,
  };

  return {
    key,
    displayName,
    workgroupRef,
  };
}

export function resourceToRef(resource?: EntityConfigResource) {
  if (!resource) { return undefined; }
  return {
    key: resource.key,
    type: resource.type,
  };
}

export function getResourceRefFromId(id: string, resources: EditorEntityResource[]) {
  return resourceToRef(resources.find((resource) => resource.id === id)?.state);
}

export function paramToRef(param?: EntityConfigParam) {
  if (!param) { return undefined; }
  return {
    key: param.key,
    type: param.type,
  };
}

export function getParamRefFromId(id: string, params: EditorEntityParam[]) {
  return paramToRef(params.find((param) => param.id === id)?.state);
}

type RefSearchResult = {
  data: EntityConfigResource | EntityConfigModule;
  path: string[];
};

const isResourceRef = (value: unknown): boolean => {
  if (!value) { return false; }
  if (typeof value !== 'object') { return false; }
  if (!('type' in value) && !('key' in value)) { return false; }
  const potentialRefValue = value as EntityConfigRef;
  if (typeof potentialRefValue.key !== 'string') { return false; }
  if (typeof potentialRefValue.type !== 'string') { return false; }
  if (!Object.values(EntityConfigResourceType)
    .includes(potentialRefValue.type as EntityConfigResourceType)) { return false; }
  return true;
};

export function getResourceReferences(
  resource: EntityConfigResource,
  resources: EditorEntityResource[],
  modules: EditorEntityModule[],
) {
  const findRefs = (
    resourceContext: EntityConfigResource | EntityConfigModule,
    path: string[],
    value: unknown,
  ): (undefined | RefSearchResult)[] => {
    if (!value) { return [undefined]; }
    if (typeof value === 'string') { return [undefined]; }
    if (typeof value === 'number') { return [undefined]; }
    if (typeof value !== 'object') { return [undefined]; }
    if (Array.isArray(value)) {
      return value.flatMap((item, i) => findRefs(resourceContext, [...path, i.toString()], item));
    }
    if (isResourceRef(value)) {
      if ((value as EntityConfigRef).key !== resource.key) {
        return [undefined];
      }
      if ((value as EntityConfigRef).type !== resource.type) {
        return [undefined];
      }
      return [{ data: resourceContext, path }];
    }
    return Object
      .entries(value)
      .flatMap(([innerKey, innerValue]) => findRefs(
        resourceContext,
        [...path, innerKey],
        innerValue,
      ));
  };

  const result = [
    ...resources.flatMap((resourceToSearch) => Object.entries(resourceToSearch.state)
      .flatMap(([key, value]) => findRefs(
        resourceToSearch.state,
        [key],
        value,
      ))),
    ...modules.flatMap((moduleToSearch) => Object.entries(moduleToSearch.state)
      .flatMap(([key, value]) => findRefs(
        moduleToSearch.state,
        [key],
        value,
      ))),
  ].filter((v) => !!v);
  return result;
}

export function getResourceReferencesByType(
  resource: EntityConfigResource,
  resources: EditorEntityResource[],
  modules: EditorEntityModule[],
) {
  const references = getResourceReferences(resource, resources, modules);
  return {
    modules: references.filter(({ data }) => {
      if ('module_type' in data) { return true; }
      return false;
    }).map(({ data }) => {
      const module = modules.find(({ id }) => id === (data as EntityConfigModule).module_type);
      return module;
    }) as EditorEntityModule[],
    resources: references.filter(({ data }) => {
      if ('type' in data) { return true; }
      return false;
    }).map(({ data }) => {
      const editorResource = resources
        .find(({ id, state: { type } }) => id === (data as EntityConfigResource).key
          && type === (data as EntityConfigResource).type);
      return editorResource;
    }) as EditorEntityResource[],
  };
}

export function serviceCategoriesScenariosToChecklistValue(
  scenarios: { v2: [string, string] }[],
): string[] {
  return scenarios.map(({ v2 }) => v2.join('|'));
}
export function utilityScenarioCategoryListToNodeList(
  list: EntityConfigUtilityCategoriesResponse | null,
): NodeList[] {
  if (!list) { return []; }
  return Object.entries(list)
    .sort(([k1], [k2]) => {
      if (k1 > k2) { return 1; }
      if (k2 > k1) { return -1; }
      return 0;
    })
    .reduce((result, [k, v]) => [
      ...result,
      {
        checked: false,
        label: k,
        value: k,
        children: v
          .filter((scenario) => scenario.v2_labels.length)
          .map((scenario) => ({
            children: scenario.v2_labels.map(([d0, d1]) => ({
              label: [
                d0 === null ? 'null' : d0,
                d1 === null ? 'null' : d1,
              ].join('|'),
              value: [
                d0 === null ? 'null' : d0,
                d1 === null ? 'null' : d1,
              ].join('|'),
              children: [],
              checked: false,
            })),
            label: scenario.name,
            value: scenario.name,
          })) ?? [],
      },
    ], [] as NodeList[]);
}
export function checklistValueToUtilityScenarioNodeList(
  list: NodeList[],
): EntityConfigUtilityCategoriesResponse {
  return list.reduce((result, current) => ({
    ...result,
    [current.value]: current.children.map((child) => ({
      name: child.label,
      v2_labels: child.children.map((childLabel) => [childLabel.value.split('|')]),
    })),
  }), {});
}

export function getDescriptionForResourceType(type: EntityConfigResourceType) {
  switch (type) {
    case EntityConfigResourceType.CCI_CONDITIONS:
      return 'An Operation, or logical condition, that must be met for an Override to be used. (Example: Is it operations hours? - Operation: time_in_partition_string with partition string MO,TU,WE,TH,FR:0700-1500.)';
    case EntityConfigResourceType.CCI_DISPATCH_RULES:
      return 'Determines the final resolution to the CCI interaction. (Example: Emergency Only Dispatch - Ask the caller if this is an emergency. Dispatch depending on the answer.)';
    case EntityConfigResourceType.DISPATCH_ACTIONS:
      return 'An action to perform upon an Incident.';
    case EntityConfigResourceType.DISPATCH_CONDITIONS:
      return 'Determines if a Schedule and Shift is enabled, based on the current time. (Example: In the Electric Schedule, is it currently After Hours?)';
    case EntityConfigResourceType.DISPATCH_PLAYBOOKS:
      return 'A list of Dispatch Actions to perform. (Example: Electric After Hours Actions invokes "Escalate to Electric AH" Action)';
    case EntityConfigResourceType.DISPATCH_POLICIES:
      return 'A collection of Dispatch Playbooks and the Rules under which those Playbooks should be invoked. (Example: Utility Locate Emails - If Dispatch Condition "Operating Hours" is True, then invoke the Daytime Playbook, otherwise invoke the default After Hours Playbook.)';
    case EntityConfigResourceType.ESCALATION_TREES:
      return 'A list of Users or Designations and when to contact them. (Example: Electric After Hours - Call On Call Designation immediately, then Bob after 2 minutes, then Jim after 10 minutes, then Steve after 15 minutes.)';
    case EntityConfigResourceType.INCIDENT_EMAILS:
      return 'An email received from a known sender token that triggers an incident.';
    case EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS:
      return 'Type name and configuration for an email handler capable of parsing the incoming email into data.';
    case EntityConfigResourceType.INCIDENT_EMAIL_ROUTERS:
      return 'Associates an email with a set of Routes for processing the email.';
    case EntityConfigResourceType.INCIDENT_ROLES:
      return 'Role applied to a user in the context of an Incident. Generally this is just Leader and Crew.';
    case EntityConfigResourceType.SCHEDULES:
      return 'A set of Recurrence Rules that determine when Shifts starts and how long they run. Also determines the default Shift, and which Shift to use for holidays. (Example: Electric Operations default Shift is After Hours. RRule of M-F:7-3 is Business Hours Shift.)';
    case EntityConfigResourceType.SERVICE_AREAS:
      return 'Geographic bounds, typically serviced by a single Workgroup. (Example: Water Service Area)';
    case EntityConfigResourceType.SERVICE_CATEGORIES:
      return 'A collection of related scenarios and how to handle them. (Example: Water/Water Leaking - d0=water, d1=water leaking)';
    case EntityConfigResourceType.SERVICE_LISTS:
      return 'A list of known names, addresses, and account numbers that have or do not have service. (Example: Electric Shutoff List)';
    case EntityConfigResourceType.SHIFTS:
      return 'A period of the day during which Incidents may be Dispatch differentlly than other periods of the day. (Example: After Hours)';
    case EntityConfigResourceType.WORKGROUP_ROLES:
      return 'How the entity organizes their employees. (Example: Managers, Water-Sewer Crew)';
    case EntityConfigResourceType.WORKGROUPS:
      return 'A workgroup is one or more departments that have a shared set of procedures.  In some cases this might be all of public works (Lander, WY), while in other cases it might make sense for each department to have their own workgroup (Olympia WA, Oakland CA).  Workgroups are logically separated from each other in terms of configurations and data.';
    case EntityConfigResourceType.FACILITY_POLICIES:
    case EntityConfigResourceType.INCIDENT_TRANSFERS:
    case EntityConfigResourceType.INCIDENT_VERSIONS:
    case EntityConfigResourceType.CCI_SCRIPTS:
    default:
      return '';
  }
}

export function getIconForResourceType(type: EntityConfigResourceType) {
  switch (type) {
    case EntityConfigResourceType.CCI_SCRIPTS:
      return 'fa-scroll';
    case EntityConfigResourceType.CCI_CONDITIONS:
      return 'fa-phone-volume';
    case EntityConfigResourceType.CCI_DISPATCH_RULES:
      return 'fa-phone-missed';
    case EntityConfigResourceType.DISPATCH_ACTIONS:
      return 'fa-bullhorn';
    case EntityConfigResourceType.DISPATCH_CONDITIONS:
      return 'fa-gears';
    case EntityConfigResourceType.DISPATCH_PLAYBOOKS:
      return 'fa-book';
    case EntityConfigResourceType.DISPATCH_POLICIES:
      return 'fa-file-check';
    case EntityConfigResourceType.ESCALATION_TREES:
      return 'fa-tree';
    case EntityConfigResourceType.FACILITY_POLICIES:
      return 'fa-building';
    case EntityConfigResourceType.INCIDENT_EMAILS:
      return 'fa-envelope';
    case EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS:
      return 'fa-inbox';
    case EntityConfigResourceType.INCIDENT_EMAIL_ROUTERS:
      return 'fa-inbox-in';
    case EntityConfigResourceType.INCIDENT_ROLES:
      return 'fa-person-digging';
    case EntityConfigResourceType.INCIDENT_TRANSFERS:
      return 'fa-arrows-cross';
    case EntityConfigResourceType.INCIDENT_VERSIONS:
      return 'fa-file-invoice';
    case EntityConfigResourceType.SCHEDULES:
      return 'fa-calendar';
    case EntityConfigResourceType.SERVICE_AREAS:
      return 'fa-map';
    case EntityConfigResourceType.SERVICE_CATEGORIES:
      return 'fa-layer-group';
    case EntityConfigResourceType.SERVICE_LISTS:
      return 'fa-file-spreadsheet';
    case EntityConfigResourceType.SHIFTS:
      return 'fa-calendar-days';
    case EntityConfigResourceType.WORKGROUP_ROLES:
      return 'fa-person';
    case EntityConfigResourceType.WORKGROUPS:
      return 'fa-people-group';
    default:
      return 'fa-square';
  }
}
